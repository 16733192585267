
.personal-informationnew {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row1>.cell1>.dd-salutation {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row1>.cell1>.dd-gender {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row1 {
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell1>.row1>.tb-full-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row2 {
    margin: 16px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell1>.row2>.tb-alias-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row1 {
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2>.row1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell2>.row1>.tb-hp-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row2 {
    margin: 16px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2>.row2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell2>.row2>.tb-hp-alias-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row3>.cell1>.tb-i-d-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row3>.cell1>.tb-dateof-birth {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row4>.cell1>.regular-dropdown27 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row4>.cell1>.dd-nationality {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row5>.cell1>.dw-nationality_copy {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row5>.cell1>.dw-residential-status {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row16 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row16>.cell1>.tb-nameof-employer {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row16>.cell1>.dw-current-annual-income {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row6 {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row8>.cell1>.tb-building-noand-street-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row8>.cell1>.tb-unit-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row9 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row9>.cell1>.dd-country-resdentail-address {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row9>.cell1>.tb-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.ismailingaddress {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row11 {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.tb-address-line1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row19 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.tb-address-line2 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.tb-state {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row13 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.tb-mailing-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row14>.cell1>.tb-mobile-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14>.cell1>.row20 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.row20>.button19 {
    display: flex;
}

.personal-informationnew>.row14>.cell1>.tb-email {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14>.cell1>.row21 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row15>.cell1>.dd-residential-ownership {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row10 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row10>.btn-back {
    width: 90px;
}

.personal-informationnew>.row10>.btn-next {
    margin: 0px 0px 0px 32px;
    width: 90px;
}

.personal-informationnew>.row11 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.overlay-popup23 {
    originaldisplayvalue: flex;
}

.personal-informationnew>.row41 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row41>.cell1>.tb-passport-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row41>.cell1>.tb-passport-expiry-date {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row41>.cell1>.custom-datepicker-expiry {
    margin: 8px 0px 0px 0px;
    padding: 5px 16px 5px 16px;
    width: 100%;
    /* border-color: rgba(154, 155, 158, 1.00); */
}

.personal-informationnew>.row42 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;

}

.personal-informationnew>.row42>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1>.lb-salutation{
    font-size: 10px;
    font-weight: bold;
    margin-left: 10px;
}

.personal-informationnew>.row42>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row42>.cell1>.tb-file-upload {
    margin: 8px 0px 8px 0px;
    width: 100%;
    cursor: pointer;
}

.personal-informationnew>.row42>.cell1>.tb-passport-upload {
    display: none;
}

.personal-informationnew>.row42>.cell2 {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell2>.file-details {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 10px;
    font-weight: normal;
    margin-left: 10px;
}

.personal-informationnew>.row42>.cell2>.success-mark {
    display: flex;
    width: 20px; 
    height: 20px; 
    margin-left: 2px;
    flex-shrink: 0; 
    align-items: center;
    justify-content: center;
}

.personal-informationnew>.row42>.cell2>.success-mark svg {
    width: 100%;
    height: 100%;
    margin-top: 4px;
}

.personal-informationnew>.row42>.cell2>.remove-icon {
    display: flex;
    width: 15px; 
    height: 15px; 
    margin-left: 2px;
    flex-shrink: 0; 
    align-items: center;
    justify-content: center;
}

/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.personal-informationnew {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
}

.personal-informationnew>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row1>.cell1>.dd-salutation {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row1>.cell1>.dd-gender {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2 {
    border-color: #000000;
    margin: 0px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1 {
    margin: 16px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2 {
    margin: 16px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row1 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell1>.row1>.tb-full-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row2 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell1>.row2>.tb-alias-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row1 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2>.row1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell2>.row1>.tb-hp-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row2 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2>.row2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell2>.row2>.tb-hp-alias-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row3>.cell1>.tb-i-d-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row3>.cell1>.tb-dateof-birth {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row4>.cell1>.regular-dropdown27 {
    width: 100%;
}

.personal-informationnew>.row4>.cell1>.dd-nationality {
    width: 100%;
}

.personal-informationnew>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row5>.cell1>.dw-nationality_copy {
    width: 100%;
}

.personal-informationnew>.row5>.cell1>.dw-residential-status {
    width: 100%;
}

.personal-informationnew>.row16 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row16>.cell1>.tb-nameof-employer {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row16>.cell1>.dw-current-annual-income {
    width: 100%;
}

.personal-informationnew>.row6 {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row8>.cell1>.tb-building-noand-street-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row8>.cell1>.tb-unit-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row9 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row9>.cell1>.dd-country-resdentail-address {
    width: 100%;
}

.personal-informationnew>.row9>.cell1>.tb-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.ismailingaddress {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row11 {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.tb-address-line1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row19 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.tb-address-line2 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.tb-state {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row13 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.tb-mailing-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row14>.cell1>.tb-mobile-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14>.cell1>.row20 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.tb-email {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14>.cell1>.row21 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row15>.cell1>.dd-residential-ownership {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row10 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row10>.btn-back {
    width: 90px;
}

.personal-informationnew>.row10>.btn-next {
    margin: 0px 0px 0px 32px;
    width: 90px;
}

.personal-informationnew>.row11 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row11>.toas-message {
    width: 100px;
    height: 100px;
}

.personal-informationnew>.overlay-popup23 {
    originaldisplayvalue: flex;
}

.personal-informationnew>.row41 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row41>.cell1>.tb-passport-number {
    width: 100%;
}

.personal-informationnew>.row41>.cell1>.tb-passport-expiry-date {
    width: 100%;
}

.personal-informationnew>.row41>.cell1>.custom-datepicker-expiry {
    margin: 8px 0px 0px 0px;
    padding: 5px 16px 5px 16px;
    width: 100%;
    /* border-color: rgba(154, 155, 158, 1.00); */
}

.personal-informationnew>.row42 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row42>.cell1>.tb-file-upload {
    width: 100%;
    cursor: pointer;
}

.personal-informationnew>.row42>.cell1>.tb-passport-upload {
    display: none;
}

.personal-informationnew>.row42>.cell2 {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell2>.file-details {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 10px;
    font-weight: normal;
    margin-left: 10px;
}

.personal-informationnew>.row42>.cell2>.success-mark {
    display: flex;
    width: 20px; 
    height: 20px; 
    margin-left: 2px;
    flex-shrink: 0; 
    align-items: center;
    justify-content: center;
}

.personal-informationnew>.row42>.cell2>.success-mark svg {
    width: 100%;
    height: 100%;
    margin-top: 4px;
}

.personal-informationnew>.row42>.cell2>.remove-icon {
    display: flex;
    width: 15px; 
    height: 15px; 
    margin-left: 2px;
    flex-shrink: 0; 
    align-items: center;
    justify-content: center;
}
}

/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.personal-informationnew {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row1>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row1>.cell1>.dd-salutation {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row1>.cell1>.dd-gender {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2 {
    border-color: #000000;
    margin: 0px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row1 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell1>.row1>.tb-full-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row2 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell1>.row2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell1>.row2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell1>.row2>.tb-alias-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row1 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2>.row1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell2>.row1>.tb-hp-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row2 {
    margin: 12px 0px 0px 0px;
    width: 100%;
}

.personal-informationnew>.row2>.cell2>.row2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row2>.cell2>.row2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row2>.cell2>.row2>.tb-hp-alias-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row3>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row3>.cell1>.tb-i-d-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row3>.cell1>.tb-dateof-birth {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row4>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row4>.cell1>.regular-dropdown27 {
    width: 100%;
}

.personal-informationnew>.row4>.cell1>.dd-nationality {
    width: 100%;
}

.personal-informationnew>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row5>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row5>.cell1>.dw-nationality_copy {
    width: 100%;
}

.personal-informationnew>.row5>.cell1>.dw-residential-status {
    width: 100%;
}

.personal-informationnew>.row16 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row16>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row16>.cell1>.tb-nameof-employer {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row16>.cell1>.dw-current-annual-income {
    width: 100%;
}

.personal-informationnew>.row6 {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row8>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row8>.cell1>.tb-building-noand-street-name {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row8>.cell1>.tb-unit-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row9 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row9>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row9>.cell1>.dd-country-resdentail-address {
    width: 100%;
}

.personal-informationnew>.row9>.cell1>.tb-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.ismailingaddress {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row11 {
    border-color: #000000;
    margin: 16px 0px 4px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row18>.cell1>.tb-address-line1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row19 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row19>.cell1>.tb-address-line2 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row12>.cell1>.tb-state {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.mailing-address-section>.row13 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.mailing-address-section>.row13>.cell1>.tb-mailing-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row14>.cell1>.tb-mobile-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14>.cell1>.row20 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row14>.cell1>.tb-email {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row14>.cell1>.row21 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row15>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row15>.cell1>.dd-residential-ownership {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.personal-informationnew>.row10 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row10>.btn-back {
    width: 90px;
}

.personal-informationnew>.row10>.btn-next {
    margin: 0px 0px 0px 32px;
    width: 90px;
}

.personal-informationnew>.row11 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row11>.toas-message {
    width: 100px;
    height: 100px;
}

.personal-informationnew>.overlay-popup23 {
    originaldisplayvalue: flex;
}

.personal-informationnew>.row41 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row41>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row41>.cell1>.tb-passport-number {
    width: 100%;
}

.personal-informationnew>.row41>.cell1>.tb-passport-expiry-date {
    width: 100%;
}

.personal-informationnew>.row41>.cell1>.custom-datepicker-expiry {
    margin: 8px 0px 0px 0px;
    padding: 5px 16px 5px 16px;
    width: 100%;
    /* border-color: rgba(154, 155, 158, 1.00); */
}

.personal-informationnew>.row42 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.personal-informationnew>.row42>.cell1>.tb-file-upload {
    width: 100%;
    cursor: pointer;
}

.personal-informationnew>.row42>.cell1>.tb-passport-upload {
    display: none;
}

.personal-informationnew>.row42>.cell2 {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-informationnew>.row42>.cell2>.file-details {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 10px;
    font-weight: normal;
    margin-left: 10px;
}

.personal-informationnew>.row42>.cell2>.success-mark {
    display: flex;
    width: 20px; 
    height: 20px; 
    margin-left: 2px;
    flex-shrink: 0; 
    align-items: center;
    justify-content: center;
}

.personal-informationnew>.row42>.cell2>.success-mark svg {
    width: 100%;
    height: 100%;
    margin-top: 4px;
}

.personal-informationnew>.row42>.cell2>.remove-icon {
    display: flex;
    width: 15px; 
    height: 15px; 
    margin-left: 2px;
    flex-shrink: 0; 
    align-items: center;
    justify-content: center;
}
}
