/* ============================================= */

/* m progress circular progress Indeterminate */
.mf-m-indeterminate-progress {
    appearance: none;
    border: none;
    background-color: transparent;
    overflow: hidden;
}

.mf-m-indeterminate-progress::-webkit-progress-bar {
    background-color: transparent;
}

.mf-m-indeterminate-progress:indeterminate {
    mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
    animation: pure-material-progress-circular 6s infinite cubic-bezier(0, 0, 1, 1);
}

:-ms-lang(x), .pure-material-progress-circular:indeterminate {
    animation: none;
}

.mf-m-indeterminate-progress:indeterminate::before, .mf-m-indeterminate-progress:indeterminate::-webkit-progress-value {
    content: "";
    display: block;
    box-sizing: border-box;
    margin-bottom: 0.25em;
    border: solid 0.25em transparent;
    border-top-color: currentColor;
    border-radius: 50%;
    width: 100% !important;
    height: 100%;
    background-color: transparent;
    animation: pure-material-progress-circular-pseudo 0.75s infinite linear alternate;
}

.mf-m-indeterminate-progress:indeterminate::-moz-progress-bar {
    box-sizing: border-box;
    border: solid 0.25em transparent;
    border-top-color: currentColor;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: transparent;
    animation: pure-material-progress-circular-pseudo 0.75s infinite linear alternate;
}

.mf-m-indeterminate-progress:indeterminate::-ms-fill {
    animation-name: -ms-ring;
}

@keyframes pure-material-progress-circular {
    0% {
        transform: rotate(0deg);
        animation-timing-function: linear;
    }

    12.5% {
        transform: rotate(180deg);
        animation-timing-function: linear;
    }

    25% {
        transform: rotate(630deg);
    }

    37.5% {
        transform: rotate(810deg);
        animation-timing-function: linear;
    }

    50% {
        transform: rotate(1260deg);
    }

    62.5% {
        transform: rotate(1440deg);
        animation-timing-function: linear;
    }

    75% {
        transform: rotate(1890deg);
    }

    87.5% {
        transform: rotate(2070deg);
        animation-timing-function: linear;
    }

    100% {
        transform: rotate(2520deg);

    }
}

@keyframes pure-material-progress-circular-pseudo {
    0% {
        transform: rotate(-30deg);
    }

    29.4% {
        border-left-color: transparent;
    }

    29.41% {
        border-left-color: currentColor;
    }

    64.7% {
        border-bottom-color: transparent;
    }

    64.71% {
        border-bottom-color: currentColor;
    }

    100% {
        border-left-color: currentColor;
        border-bottom-color: currentColor;
        transform: rotate(225deg);
    }
}

/* indeterminate arc color */
.mf-m-indeterminate-progress.sample {
    color: #3e53d3;
}

/* .mf-m-indeterminate-progress.sample::before{
    margin-bottom: 20px;
} */


/* ================================================= */

/* Material Determinate Progress  */

.mf-m-determinate-progress {
    position: relative;
}

.mf-m-determinate-progress>.inner-circle {
    animation: rotate 3s linear infinite;
    height: 100%;
    position: relative;
    width: 100%;
}

.mf-m-determinate-progress>.inner-circle>.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
}

.mf-m-determinate-progress>.determinate-inner-circle {
    height: 100%;
    width: 100%;
    transform: rotate(-90deg);
}

.mf-m-determinate-progress>.progress-value {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ======================================== */

.mf-m-determinate-progress.sample {
    width: 100px;
    height: 100px;

}

/* determinate base color */
.mf-m-determinate-progress.sample>.determinate-inner-circle>.determinate-track {
    stroke: #ddd;
}

/* determinate arc color  */
.mf-m-determinate-progress.sample>.determinate-inner-circle>.determinate-indication {
    stroke: #07c;
}

/* determinate lable color */
.mf-m-determinate-progress.sample>.progress-value {
    white-space: normal;
    overflow: hidden;
    color: rgb(107, 96, 84);
    text-align: left;
    text-decoration: unset;
    font-size: 15px;
    text-overflow: unset;
    font-style: italic;
    font-weight: bolder;
}




/* ======================================== */

/* Linear progress bar */

.mf-m-linearprogress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.mf-m-linearprogress-bar>.progress {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-height: 2px;
}

.mf-m-linearprogress-bar>.progress>.determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    transition: width 0.2s linear;
}

.mf-m-linearprogress-bar>.progress>.progress-label {
    position: absolute;
    color: inherit;
    top: 0;
    right: 0;
    margin-left: 8px;
}

.mf-m-linearprogress-bar>.progress>.indeterminate {
    border-radius: inherit;
}

.mf-m-linearprogress-bar>.progress-label {
    margin-left: 8px;
}

.mf-m-linearprogress-bar>.progress>.indeterminate:before {
    content: "";
    position: absolute;
    background-color: inherit;
    border-radius: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.mf-m-linearprogress-bar>.progress>.indeterminate:after {
    content: "";
    position: absolute;
    border-radius: inherit;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }

    50% {
        transform: scaleX(1.1);
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}

/* ================================== */

.mf-m-linearprogress-bar.sample {
    width: 300px;
    height: 6px;
    margin: 12px 0px;
}

/* base color for derterminate & indeterminate */
.mf-m-linearprogress-bar.sample>.progress {
    border-color: #f5f1f1;
    background-color: #e8eff8;
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
}

/* determinate line color */
.mf-m-linearprogress-bar.sample>.progress>.determinate {
    width: 60%;
    background-color: #293cd3;
}

/* lable color  */
.mf-m-linearprogress-bar.sample>.progress-label {
    color: #293cd3;
    font-size: 12px;
}

/* indeterminate line color */
.mf-m-linearprogress-bar.sample>.progress>.indeterminate {
    background-color: #293cd3 ;
}


/*================================= n-detrminate progress bar======*/