.accredited-investor-popup {
    padding: 0px 24px 24px 24px;
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    overflow-y: auto;
    border-width: 0px;
    width: 440px;
    border-style: solid;
    height: 460px;
}

.accredited-investor-popup>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.accredited-investor-popup>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.accredited-investor-popup>.row2>.row12 {
    padding: 8px 8px 8px 8px;
    border-color: rgba(197, 184, 184, 1.00);
    margin: 0px 8px 0px 8px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-radius: 4px;
    border-width: 1px;
    width: calc(100% - 16px);
    border-style: solid;
}

.accredited-investor-popup>.row2>.row12>.label7 {
    margin: 8px 0px 0px 0px;
}

.accredited-investor-popup>.row2>.row12>.label8 {
    margin: 4px 0px 0px 0px;
}

.accredited-investor-popup>.row2>.row12>.label9 {
    margin: 24px 0px 0px 0px;
}

.accredited-investor-popup>.row2>.row12>.label10 {
    margin: 8px 0px 0px 0px;
}

.accredited-investor-popup>.row2>.row12>.label11 {
    margin: 4px 0px 0px 0px;
}

.accredited-investor-popup>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.accredited-investor-popup>.row13 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.accredited-investor-popup>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    justify-content: space-between;
}

.accredited-investor-popup>.row4>.row14 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0px 0px 12px 0px;
}

.accredited-investor-popup>.row4>.row14>.row7 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0px 0px 0px 8px;
}

.accredited-investor-popup>.row4>.row14>.row15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 0px 12px;
}

.accredited-investor-popup>.row4>.row14>.row7>.row8 {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.accredited-investor-popup>.row4>.row14>.label21 {
    margin: 0px 0px 0px 12px;
}

/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


    .accredited-investor-popup {
        padding: 0px 24px 24px 24px;
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 400px;
        border-style: solid;
        height: 460px;
    }

    .accredited-investor-popup>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row2>.row12 {
        padding: 8px 8px 8px 8px;
        border-color: rgba(197, 184, 184, 1.00);
        margin: 0px 8px 0px 8px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-radius: 4px;
        border-width: 1px;
        width: calc(100% - 16px);
        border-style: solid;
    }

    .accredited-investor-popup>.row2>.row12>.label7 {
        margin: 8px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label8 {
        margin: 4px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label9 {
        margin: 24px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label10 {
        margin: 8px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label11 {
        margin: 4px 0px 0px 0px;
    }

    .accredited-investor-popup>.row3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row13 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row4>.row14>.row7 {
        flex-wrap: wrap;
    }

    .accredited-investor-popup>.row4>.row14>.row15 {
        flex-wrap: wrap;
    }

    .accredited-investor-popup>.row4>.row14>.row7>.row8 {
        flex-wrap: wrap;
    }
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


    .accredited-investor-popup {
        padding: 0px 24px 24px 24px;
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 320px;
        border-style: solid;
        height: 460px;
    }

    .accredited-investor-popup>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row2>.row12 {
        padding: 8px 8px 8px 8px;
        border-color: rgba(197, 184, 184, 1.00);
        margin: 0px 8px 0px 8px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-radius: 4px;
        border-width: 1px;
        width: calc(100% - 16px);
        border-style: solid;
    }

    .accredited-investor-popup>.row2>.row12>.label7 {
        margin: 8px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label8 {
        margin: 4px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label9 {
        margin: 24px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label10 {
        margin: 8px 0px 0px 0px;
    }

    .accredited-investor-popup>.row2>.row12>.label11 {
        margin: 4px 0px 0px 0px;
    }

    .accredited-investor-popup>.row3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row13 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .accredited-investor-popup>.row4>.row14>.row7 {
        flex-wrap: wrap;
    }

    .accredited-investor-popup>.row4>.row14>.row15 {
        flex-wrap: wrap;
    }

    .accredited-investor-popup>.row4>.row14>.row7>.row8 {
        flex-wrap: wrap;
    }

}