.message-popup {
    width: 100% !important;
    background: #d5d5d59d;
}

.message-popup>.window {
    width: 300px;
    height: auto;
    border: 4px;
    border-radius: 12px;
}

.message-popup>.window>.header {
    background-color: #ffb400;
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px 12px 0px 0px;
}

.message-popup>.window>.content {
    padding: 24px;
    background-color: #f5f5f5;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-popup>.window>.content>.content {
    display: flex;
    flex-direction: column;
}

.message-popup>.window>.content>.content>.paragraph:first-child {
    margin: 4px 0px;
}

.message-popup>.window>.content>.content>.paragraph {
    margin: 24px 0px;
    font-size: 12px;
}

.message-popup>.window>.content>.content>.buttonok {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.message-popup>.window>.content>.content>.buttonok>.btn {
    width: 50px;
    background-color: #f5f5f5;
    border: 1px solid #ffb400;
    outline: none;
    padding: 4px 12px;
    border-radius: 12px;
    font-weight: 400;
    color: #ffb400;
}