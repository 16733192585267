.ai-tooltip {
    padding: 0px 24px 24px 24px;
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    overflow-y: auto;
    max-height: 400px;
    border-width: 0px;
    width: 500px;
    border-style: solid;
}

.ai-tooltip>.row1 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 30px;
}

.ai-tooltip>.row2 {
    padding: 0px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .ai-tooltip {
        padding: 0px 24px 24px 24px;
        background-color: rgba(255, 255, 255, 0);
        border-color: #ffffff;
        display: block;
        overflow-y: auto;
        max-height: 400px;
        border-width: 0px;
        width: 400px;
        border-style: solid;
    }

    .ai-tooltip>.row1 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 30px;
    }

    .ai-tooltip>.row2 {
        padding: 0px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

}

/* ============================================================== */

/* Mobile Mode */
@media screen and (max-device-width: 480px) {
    .ai-tooltip {
        padding: 0px 24px 24px 24px;
        background-color: rgba(255, 255, 255, 0);
        border-color: #ffffff;
        display: block;
        overflow-y: auto;
        max-height: 300px;
        border-width: 0px;
        width: 320px;
        border-style: solid;
    }

    .ai-tooltip>.row1 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 30px;
    }

    .ai-tooltip>.row2 {
        padding: 0px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

}
