.mf-dropdown-with-input-search {
    display: flex;
    position: relative;
    overflow: visible !important;
}

.mf-dropdown-with-input-search>.search-box {
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
}

.mf-dropdown-with-input-search>.cancel-icon {
    position: absolute;
    right: 2px;
}

.mf-dropdown-with-input-search>.popup {
    position: absolute;
    z-index: 50;
    outline: none;
    list-style: none;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    left: 0;
    max-height: 250px;
}

.mf-dropdown-with-input-search>.popup::-webkit-scrollbar {
    width: 3px;
    background-color: #d6d6d6;
}

.mf-dropdown-with-input-search>.popup::-webkit-scrollbar-thumb {
    background-color: #1a73e8;
}

.mf-dropdown-with-input-search:focus-within>.popup {
    visibility: visible;
}

.mf-dropdown-with-input-search>.cancel-icon {
    height: 20px;
    width: 20px;
}

.mf-dropdown-with-input-search>.cancel-icon>svg,
.mf-dropdown-with-input-search>.cancel-icon>img {
    height: 100%;
    width: 100%;
    fill: #979797;
    cursor: pointer;
}

.mf-dropdown-with-input-search>.cancel-icon>svg:hover {
    fill: #4cc0ee;
}

.mf-dropdown-with-input-search>.popup>.row-drop-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mf-dropdown-with-input-search>.popup>.no-suchdatafound {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mf-dropdown-with-input-search>.popup>.row-drop-item>.title.no-suchdatafound {
    justify-content: center;
}

.mf-dropdown-with-input-search>.popup>.row-drop-item>.data>.highlighted {
    font-weight: bold;
}

.mf-dropdown-with-input-search>.popup>.row-drop-item.select-item {
    background-color: #7d7b7b;
}

.mf-dropdown-with-input-search>.caret {
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #888;
    pointer-events: none;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>> sample css >>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.mf-dropdown-with-input-search.sample {
    width: 200px;
    border: 1px solid gainsboro;
    padding: 8px 16px;
    border-radius: 5px;
}

.mf-dropdown-with-input-search.sample:focus-within {
    border: 2px solid #4cc0ee;
}

.mf-dropdown-with-input-search.sample>.popup {
    background-color: #ffffff;
    box-shadow: #00000029 0px 1px 4px;
    max-height: 240px;
    width: 400px;
}

.mf-dropdown-with-input-search.sample>.popup>.row-drop-item {
    padding: 8px 16px;
}

.mf-dropdown-with-input-search.sample>.popup>.row-drop-item:hover {
    background-color: #f5f5f5;
}

.mf-dropdown-with-input-search.sample>.popup>.row-drop-item.active {
    background: #c7c7c7;
}

.mf-dropdown-with-input-search.sample>.popup>.no-suchdatafound>.no-data {
    color: #d6d6d6;
}

.mf-dropdown-with-input-search.sample>.popup>.row-drop-item>.data>.highlighted {}

.mf-dropdown-with-input-search.sample>.popup>.no-suchdatafound:hover.no-suchdatafound {
    background: #ffffff;
}

.mf-dropdown-with-input-search.sample>.cancel-icon {
    width: 16px;
    height: 16px;
}