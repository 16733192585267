.tax-w8-b-e-n-part1v2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-n-part1v2>.row8 {
    padding: 20px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-n-part1v2>.row1 {
    border-color: #000000;
    margin: 40px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row1>.cell1>.tb-nameofindividualwhoisthebeneficialowner {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row1>.cell1>.dd-countryof-citizenship {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row2>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row2>.cell1>.tb-permanent-residence-address {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row2>.cell1>.dd-permanent-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row3>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row3>.cell1>.tb-mailing-address {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row3>.cell1>.dd-mailing-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row4>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row4>.cell1>.tb-u-staxpayeridentificationnumber {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row6 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row6>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row6>.cell1>.regular-consent-checkbox-style1 {
    padding-top: 10px;
}

.tax-w8-b-e-n-part1v2>.row6>.cell1>.tb-foreign-tax-identifying-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row6>.regular-consent-checkbox8 {
    width: 49%;
}

.tax-w8-b-e-n-part1v2>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row5>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row5>.cell1>.tb-reference-numbers {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-w8-b-e-n-part1v2>.row5>.cell2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-w8-b-e-n-part1v2>.row5>.cell2>.tb-dateof-birth {
    margin: 8px 0px 2px 0px;
    width: 100%;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .tax-w8-b-e-n-part1v2 {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row8 {
        padding: 20px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        max-width: 1200px;
    }
    .tax-w8-b-e-n-part1v2>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row1>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row1>.cell1>.tb-nameofindividualwhoisthebeneficialowner {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row1>.cell1>.dd-countryof-citizenship {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row2>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row2>.cell1>.tb-permanent-residence-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row2>.cell1>.dd-permanent-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row3>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row3>.cell1>.tb-mailing-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row3>.cell1>.dd-mailing-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row4 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row4>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row4>.cell1>.tb-u-staxpayeridentificationnumber {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row6 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row6>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row6>.cell1>.tb-foreign-tax-identifying-number {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row6>.regular-consent-checkbox8 {
        margin: 16px 0px 0px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row5 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell1>.tb-reference-numbers {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell2>.tb-dateof-birth {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .tax-w8-b-e-n-part1v2 {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row8 {
        padding: 20px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        max-width: 1200px;
    }
    .tax-w8-b-e-n-part1v2>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row1>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row1>.cell1>.tb-nameofindividualwhoisthebeneficialowner {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row1>.cell1>.dd-countryof-citizenship {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row2>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row2>.cell1>.tb-permanent-residence-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row2>.cell1>.dd-permanent-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row3>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row3>.cell1>.tb-mailing-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row3>.cell1>.dd-mailing-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row4 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row4>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row4>.cell1>.tb-u-staxpayeridentificationnumber {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row6 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row6>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row6>.cell1>.tb-foreign-tax-identifying-number {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row6>.regular-consent-checkbox8 {
        margin: 16px 0px 0px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row5 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell1>.tb-reference-numbers {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-part1v2>.row5>.cell2>.tb-dateof-birth {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
}