.choose-accountsv2 {
    background-color: #ffffff;
    border-color: #ffffff;
    flex-direction: column;
    display: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.choose-accountsv2>.row10 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.choose-accountsv2>.row13 {
    width: 100%;
    display: flex;
    justify-content: center;
}

.choose-accountsv2>.row13>button {
    margin: 0px 4px;
}

.choose-accountsv2>.row10>.section1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section1>.row-margin {
    padding: 16px 16px 16px 16px;
    border-color: rgba(154, 155, 158, 1.00);
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    min-height: 100px;
    border-radius: 8px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section1>.row-margin>.ccb-p-a {
    width: 100%;
}

.choose-accountsv2>.row10>.section1>.row-cash {
    padding: 16px 16px 16px 16px;
    border-color: rgba(154, 155, 158, 1.00);
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    min-height: 100px;
    border-radius: 8px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section1>.row-cash>.ccb-c-a {
    width: 100%;
}

.choose-accountsv2>.row10>.section2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section2>.row-margin {
    padding: 16px 16px 16px 16px;
    border-color: rgba(154, 155, 158, 1.00);
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    min-height: 100px;
    border-radius: 8px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section2>.row-margin>.ccb-m-a {
    width: 100%;
}

.choose-accountsv2>.row10>.section2>.row-cash {
    padding: 16px 16px 16px 16px;
    border-color: rgba(154, 155, 158, 1.00);
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    min-height: 100px;
    border-radius: 8px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section2>.row-cash>.ccb-f-i-m-a {
    width: 100%;
}

.choose-accountsv2>.row10>.section3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section3>.row-margin {
    padding: 16px 16px 16px 16px;
    border-color: rgba(154, 155, 158, 1.00);
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    min-height: 100px;
    border-radius: 8px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section3>.row-margin>.ccb-c-f-d {
    width: 100%;
}

.choose-accountsv2>.row10>.section3>.row-cash {
    padding: 16px 16px 16px 16px;
    border-color: rgba(154, 155, 158, 1.00);
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    min-height: 100px;
    border-radius: 8px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.choose-accountsv2>.row10>.section3>.row-cash>.ccb-l-f-x {
    width: 100%;
}

.choose-accountsv2>.row29 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.choose-accountsv2>.row29>.u-i-component30 {
    width: 100%;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .choose-accountsv2 {
        background-color: #ffffff;
        border-color: #ffffff;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .choose-accountsv2>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1>.row-margin {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1>.row-margin>.ccb-p-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section1>.row-cash {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1>.row-cash>.ccb-c-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section2>.row-margin {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section2>.row-margin>.ccb-m-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section2>.row-cash {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section2>.row-cash>.ccb-f-i-m-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section3>.row-margin {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section3>.row-margin>.ccb-c-f-d {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section3>.row-cash {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section3>.row-cash>.ccb-l-f-x {
        width: 100%;
    }
    .choose-accountsv2>.row29 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .choose-accountsv2>.row29>.u-i-component30 {
        width: 100%;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .choose-accountsv2 {
        background-color: #ffffff;
        border-color: #ffffff;
        flex-direction: column;
        display: block;
        border-width: 0px;
        border-style: solid;
    }
    .choose-accountsv2>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1>.row-margin {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1>.row-margin>.ccb-p-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section1>.row-cash {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section1>.row-cash>.ccb-c-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section2>.row-margin {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section2>.row-margin>.ccb-m-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section2>.row-cash {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section2>.row-cash>.ccb-f-i-m-a {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section3>.row-margin {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section3>.row-margin>.ccb-c-f-d {
        width: 100%;
    }
    .choose-accountsv2>.row10>.section3>.row-cash {
        padding: 16px 16px 16px 16px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        min-height: 100px;
        border-radius: 8px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }
    .choose-accountsv2>.row10>.section3>.row-cash>.ccb-l-f-x {
        width: 100%;
    }
    .choose-accountsv2>.row29 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .choose-accountsv2>.row29>.u-i-component30 {
        width: 100%;
    }
}