.mf-tooltip-container {
    display: flex;
    position: relative;
}

.mf-tooltip-container>.mf-tooltip-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mf-tooltip-container>.mf-tooltip-icon>svg {
    width: inherit;
    height: inherit;
}

.mf-tooltip-container>.mf-tooltip-popup {
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 4px 8px;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-title {
    white-space: nowrap;
    flex: 1;
    font-size: 20px;
    font-weight: 700;
    padding-left: 8px;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close {
    display: flex;
    margin-left: auto;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close>.mf-tooltip-popup-close-icon {
    cursor: pointer;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.mf-tooltip-container>.mf-popup-tooltip-arrow {
    cursor: pointer;
}

/* ======================== sample ======================== */

.mf-tooltip-container.sample {}

.mf-tooltip-container.sample>.mf-tooltip-icon {
    width: 100px;
    height: 50px;
    background-color: #6200ee;
    border-radius: 50%;
}

.mf-tooltip-container.sample>.mf-tooltip-icon>.fallback-text {
    font-size: 18px;
    color: #ffffff;
}

.mf-tooltip-container.sample>.mf-tooltip-popup {
    padding: 10px;
    background: #f5f5f5;
    color: #121212;
    border-radius: 4px;
    box-shadow: 1px 1px 3px #b6b6b6;
    border: 1px solid red;
    opacity: 0;
}

.mf-tooltip-container.sample>.mf-tooltip-popup>.mf-tooltip-popup-header {
    /* border-bottom: 1px solid #BDC5C0; */
    padding: 8px 12px;
}

.mf-tooltip-container.sample>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-title {
    font-size: 16px;
    font-weight: 600;
    color: #22252B;
}

.mf-tooltip-container.sample>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close {
    width: 20px;
    height: 20px;
}

.mf-tooltip-container.sample>.mf-popup-tooltip-arrow {
    width: 20px;
    height: 20px;
    background: red;
}

.mf-tooltip-container {
    display: flex;
    position: relative;
}

.mf-tooltip-container>.mf-tooltip-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mf-tooltip-container>.mf-tooltip-icon>svg {
    width: inherit;
    height: inherit;
}

.mf-tooltip-container>.mf-tooltip-popup {
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-title {
    white-space: nowrap;
    flex: 1;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close {
    display: flex;
    margin-left: auto;
}

.mf-tooltip-container>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close>.mf-tooltip-popup-close-icon {
    cursor: pointer;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.mf-tooltip-container>.mf-popup-tooltip-arrow {
    cursor: pointer;
}

/* ======================== sample ======================== */

.mf-tooltip-container.sample {}

.mf-tooltip-container.sample>.mf-tooltip-icon {
    width: 100px;
    height: 50px;
    background-color: #6200ee;
    border-radius: 50%;
}

.mf-tooltip-container.sample>.mf-tooltip-icon>.fallback-text {
    font-size: 18px;
    color: #ffffff;
}

.mf-tooltip-container.sample>.mf-tooltip-popup {
    padding: 10px;
    background: #f5f5f5;
    color: #121212;
    border-radius: 4px;
    box-shadow: 1px 1px 3px #b6b6b6;
    border: 1px solid red;
    opacity: 0;
}

.mf-tooltip-container.sample>.mf-tooltip-popup>.mf-tooltip-popup-header {
    /* border-bottom: 1px solid #BDC5C0; */
    padding: 8px 12px;
}

.mf-tooltip-container.sample>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-title {
    font-size: 16px;
    font-weight: 600;
    color: #22252B;
}

.mf-tooltip-container.sample>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close {
    width: 20px;
    height: 20px;
}

.mf-tooltip-container.sample>.mf-popup-tooltip-arrow {
    width: 20px;
    height: 20px;
    background: red;
}