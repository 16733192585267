.verify-mobileor-email {
    padding: 30px 30px 30px 30px;
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 300px;
    border-style: solid;
    height: 400px;
}

.verify-mobileor-email>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    /* justify-content: center; */
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.verify-mobileor-email>.row1>.changemobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    padding-right: 12px;
}

.verify-mobileor-email>.row1>.row1 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.verify-mobileor-email>.row1>.row1>input {
    width: 100%;
}

.verify-mobileor-email>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    /* justify-content: space-around; */
    border-width: 0px;
    border-style: solid;
    height: 50px;
}

.verify-mobileor-email>.row1>.row2>input {
    width: 100%;
    border-radius: 0px 4px 4px 0px;
}

.verify-mobileor-email>.row1>.row2>.mf-dropdown {
    width: 100px;
    /* margin: 0px; */
}

.verify-mobileor-email>.row1>.row4 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.verify-mobileor-email>.row1>.row3 {
    padding: 12px 0px 0px 0px;
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.verify-mobileor-email>.row1>.row3>.countdown-timer7 {
    margin: 6px 0px 0px 0px;
    width: 40px;
}

.verify-mobileor-email>.row1>.row3>.verify-row {
    padding: 12px 0px 0px 0px;
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .verify-mobileor-email {
        padding: 30px 30px 30px 30px;
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 400px;
        border-style: solid;
        height: 400px;
    }
    .verify-mobileor-email>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .verify-mobileor-email>.row1>.row1 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .verify-mobileor-email>.row1>.row2 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        /* justify-content: space-around; */
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .verify-mobileor-email>.row1>.row4 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .verify-mobileor-email>.row1>.row3 {
        padding: 12px 0px 0px 0px;
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .verify-mobileor-email>.row1>.row3>.countdown-timer7 {
        margin: 6px 0px 0px 0px;
        width: 40px;
    }
    .verify-mobileor-email>.row1>.row3>.verify-row {
        padding: 12px 0px 0px 0px;
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .verify-mobileor-email {
        padding: 30px 30px 30px 30px;
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 320px;
        border-style: solid;
        height: 400px;
    }
    .verify-mobileor-email>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .verify-mobileor-email>.row1>.row1 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .verify-mobileor-email>.row1>.row2 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .verify-mobileor-email>.row1>.row4 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .verify-mobileor-email>.row1>.row3 {
        padding: 12px 0px 0px 0px;
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .verify-mobileor-email>.row1>.row3>.countdown-timer7 {
        margin: 6px 0px 0px 0px;
        width: 40px;
    }
    .verify-mobileor-email>.row1>.row3>.verify-row {
        padding: 12px 0px 0px 0px;
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-around;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
}