.mf-formattedinputbox-container {
    user-select: none;
    outline: none;
    user-select: none;
    letter-spacing: 12px;

}

.mf-formattedinputbox-container::selection {
    background-color: transparent;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> sample <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */

.mf-formattedinputbox-container.sample {
    color: #121212;
    border: 1px solid #b8b8b8;
    border-radius: 4px;
    padding: 8px 16px;
    background: #ffffff;
    text-align: center;
    font-size: 14px;
    width: 240px;
    height: 42px;
}

.mf-formattedinputbox-container.sample:hover {
    border-color: #b6b6b6;
}

.mf-formattedinputbox-container.sample:focus {
    border-color: #6200EE;
}