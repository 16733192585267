.c-f-dand-l-f-x {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.lb-contractsfor-differences4_copy_copy_copy {
    margin: 24px 0px 0px 0px;
}

.c-f-dand-l-f-x>.c-f-d>.row1 {
    background-color: rgba(88, 185, 80, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row1>.row1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row1>.row1>.cell1>.tb-passedundergonecfdmodule {
    margin: 0px 0px 2px 0px;
    width: 100%;
}

.c-f-dand-l-f-x>.c-f-d>.row6 {
    background-color: rgba(112, 163, 108, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row6>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1>.cell1>.tb-specifycfd-email-address {
    margin: 8px 0px 2px 0px;
    width: 49%;
}

.c-f-dand-l-f-x>.c-f-d>.row6>.row2 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.lb-contractsfor-differences4_copy_copy_copy {
    margin: 24px 0px 0px 0px;
}

.c-f-dand-l-f-x>.l-f-x>.row1 {
    background-color: rgba(88, 185, 80, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row1>.row1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row1>.row1>.cell1>.tb-passedundergonelfxmodule {
    margin: 0px 0px 2px 0px;
    width: 100%;
}

.c-f-dand-l-f-x>.l-f-x>.row6 {
    background-color: rgba(112, 163, 108, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row6>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1>.cell1>.tb-specifylfx-email-address {
    margin: 8px 0px 2px 0px;
    width: 49%;
}

.c-f-dand-l-f-x>.l-f-x>.row6>.row2 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd {
    background-color: rgba(88, 185, 80, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.cfdlabel {
    margin: 24px 0px 0px 0px;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1>.cell1>.cfdmodulepassed {
    margin: 0px 0px 2px 0px;
    width: 100%;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.lfxlabel {
    margin: 24px 0px 0px 0px;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.cell1>.lfxmodulepassed {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes>.cell1>.txt-email-addresscfd {
    margin: 8px 0px 2px 0px;
    width: 49%;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.desctext>label {
    font-size: 14px;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.desccfd {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.desclfx {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-dand-l-f-x>.c-f-dand-l-f-x>.descbothnotselect {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .c-f-dand-l-f-x {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.lb-contractsfor-differences4_copy_copy_copy {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1>.row1>.cell1>.tb-passedundergonecfdmodule {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1>.cell1>.tb-specifycfd-email-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.lb-contractsfor-differences4_copy_copy_copy {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1>.row1>.cell1>.tb-passedundergonelfxmodule {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1>.cell1>.tb-specifylfx-email-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.cfdlabel {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1>.cell1>.cfdmodulepassed {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.lfxlabel {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.cell1>.lfxmodulepassed {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes>.cell1>.txt-email-addresscfd {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.desccfd {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.desclfx {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.descbothnotselect {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .c-f-dand-l-f-x {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.lb-contractsfor-differences4_copy_copy_copy {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row1>.row1>.cell1>.tb-passedundergonecfdmodule {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row1>.row1>.cell1>.tb-specifycfd-email-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-d>.row6>.row2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.lb-contractsfor-differences4_copy_copy_copy {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row1>.row1>.cell1>.tb-passedundergonelfxmodule {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row1>.row1>.cell1>.tb-specifylfx-email-address {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.l-f-x>.row6>.row2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.cfdlabel {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.cfd>.row1>.cell1>.cfdmodulepassed {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.lfxlabel {
        margin: 24px 0px 0px 0px;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.lfx>.cell1>.lfxmodulepassed {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.bothyes>.cell1>.txt-email-addresscfd {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.desccfd {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.desclfx {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-dand-l-f-x>.c-f-dand-l-f-x>.descbothnotselect {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
}