
.authenticate {
    background-color: #ffffff;
    border-color: #ffffff;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.authenticate>.row1 {
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.authenticate {
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 0px;
    border-style: solid;
}

.authenticate>.row1 {
    border-color: #000000;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.authenticate {
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 0px;
    border-style: solid;
}

.authenticate>.row1 {
    border-color: #000000;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}
}

