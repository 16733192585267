.mf-custom-datepicker-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    overflow: visible !important;
}

.mf-custom-datepicker-container>.mf-custom-datepicker-inputfieldbox {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    width: 100%;
}

.mf-custom-datepicker-container>.mf-custom-datepicker-inputfieldbox>.mf-custom-datepicker-spanicon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
}

.mf-custom-datepicker-container>.mf-custom-datepicker-inputfieldbox>.mf-custom-datepicker-spanicon {
    font-size: 0px;
}

.mf-custom-datepicker-container>.mf-custom-datepicker-inputfieldbox>.mf-custom-datepicker-spanicon>img {
    width: 100%;
    height: 100%;
}

.mf-custom-datepicker-container>.mf-custom-datepicker-inputfieldbox>.mf-custom-datepicker-input {
    outline: none;
    user-select: none;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}


/* calendar css */

.mf-custom-datepicker-container>.mf-custom-datepicker-outer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
    background-color: transparent;
}

.mf-custom-datepicker-container>.mf-custom-calendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    z-index: 4;
    top: 0;
    opacity: 0;
    transition: all .25s ease-in-out;
    margin-top: 5px;
}

.mf-custom-datepicker-container>.mf-custom-calendar.active {
    opacity: 1;
    z-index: 4;
    top: 100%;
    transition: all .25s ease-in-out;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 3fr 1fr 2fr 1fr;
    width: 100%;
    padding: 8px 0;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.mf-custom-calendar-monthname {
    cursor: pointer;
    font-size: 15px;
    color: #121212;
    font-weight: bold;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder {
    position: relative;
    overflow-y: auto;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-weeknames {
    padding: 10px 0px;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-weeknames,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px 5px;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days {
    background-color: #ffffff;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-weeknames>.mf-custom-calendar-weeknamesitem {
    color: #a7a7a7;
    font-size: 10px;
    font-weight: 500;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-weeknames>.mf-custom-calendar-weeknamesitem,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>span {
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
    transition: all .2s ease;
    width: 36px;
    height: 36px;
    align-content: flex-start;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem {
    padding: 0 20px;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem {
    border-radius: 2px;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.prevday {
    cursor: default;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.monthdays {
    color: #121212;
    border: 1px solid transparent;
    border-radius: 50%;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.monthdays.disabled,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.monthdays.past {
    opacity: .3;
    pointer-events: none;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    grid-template-columns: repeat(5, 1fr);
    padding: 0px;
    background-color: #f5f5f5;
    overflow-y: auto !important;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year::-webkit-scrollbar {
    width: 2px;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year.hidden {
    display: none;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.monthdays:hover {
    transition: all .2s ease;
    background-color: #b6b6b6;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.monthdays.selected:hover {
    border-color: #ffbc00;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.selected,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem.selected {
    background: #ffbc00;
    color: #ffffff;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.today {
    transition: all .2s ease;
    border-color: #ffbc00;
    /* background-color: #ffbc00; */
    color: rgb(4, 3, 3);
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem.today {
    font-weight: bold;
    color: #ffbc00;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.today.selected,
.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem.today.selected {
    color: #ffffff;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.left-arrow:hover>svg {
    transform: translateX(-1px);
    transition: all .5s ease;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.right-arrow:hover>svg {
    transform: translateX(1px);
    transition: all .5s ease;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.down-arrow:hover>svg {
    transform: translateY(1px);
    transition: all .5s ease;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.rotate {
    transform: rotate(180deg);
    transition: all .5s ease;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.nav-arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 12px;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.nav-arrows.disabled {
    color: grey;
    opacity: .3;
    pointer-events: none;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.nav-arrows>svg {
    transition: all .2s ease;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.nav-arrows:hover {
    background: #dddddda3;
}

.mf-custom-datepicker-container>.mf-custom-calendar>.mf-custom-calendar-month>.nav-arrows>svg {
    width: 10px;
    height: 10px;
}


/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> sample <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */

.mf-custom-datepicker-container.sample {
    color: grey;
    border: 1px solid #b8b8b8;
    border-radius: 4px;
    padding: 10px;
    background: #ffffff;
    margin: auto;
}

.mf-custom-datepicker-container.sample.error {
    border: 1px solid #cc3737;
}

.mf-custom-datepicker-container.sample.disabled {
    user-select: none;
}

.mf-custom-datepicker-container.sample:focus-within {
    border-color: #ffbc00;
}

.mf-custom-datepicker-container.sample>.mf-custom-datepicker-inputfieldbox>.mf-custom-datepicker-input {
    background-color: transparent;
    color: #121212;
    font-size: 25px;
}

.mf-custom-datepicker-container.sample>.mf-custom-calendar {
    box-shadow: 1px 1px 3px #80808082;
    border-radius: 4px;
    background: #ffffff;
    padding: 5px 7px;
}

.mf-custom-datepicker-container.sample>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem,
.mf-custom-datepicker-container.sample>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem {
    font-size: 14px;
}

.mf-custom-datepicker-container.sample>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year {
    background: #f5f5f5;
}

.mf-custom-datepicker-container.sample>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.mf-custom-calendar-dayitem.selected,
.mf-custom-datepicker-container.sample>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem.selected {
    background: #ffbc00;
    color: #ffffff;
    box-shadow: 1px 1px 3px #80808082;
}