.personal-info-page {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.personal-info-page>.container {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.personal-info-page>.container>.section1 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.personal-info-page>.container>.section1>.u-i-component6 {
    width: 100%;
}

.personal-info-page>.container>.section2 {
    padding: 20px 0px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.personal-info-page>.container>.section3 {
    padding: 0px 20px 40px 20px;
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.personal-info-page>.container>.section3>.details {
    width: 100%;
}

.personal-info-page>.container>.section3>.toast {
    width: 100%;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .personal-info-page {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
        height: 100%;
    }
    .personal-info-page>.container {
        background-color: rgba(255, 255, 255, 0);
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        overflow-x: hidden;
        overflow-y: auto;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .personal-info-page>.container>.section1 {
        background-color: rgba(221, 222, 223, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .personal-info-page>.container>.section1>.u-i-component6 {
        width: 100%;
    }
    .personal-info-page>.container>.section2 {
        padding: 20px 0px 12px 20px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        max-width: 1200px;
    }
    .personal-info-page>.container>.section3 {
        padding: 0px 20px 40px 20px;
        border-color: #000000;
        margin: 20px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        max-width: 1200px;
    }
    .personal-info-page>.container>.section3>.details {
        width: 100%;
    }
    .personal-info-page>.container>.section3>.toast {
        width: 100%;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .personal-info-page {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
        height: 100%;
    }
    .personal-info-page>.container {
        background-color: rgba(255, 255, 255, 0);
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        overflow-x: hidden;
        overflow-y: auto;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .personal-info-page>.container>.section1 {
        background-color: rgba(221, 222, 223, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .personal-info-page>.container>.section1>.u-i-component6 {
        width: 100%;
    }
    .personal-info-page>.container>.section2 {
        padding: 20px 0px 12px 20px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        max-width: 1200px;
    }
    .personal-info-page>.container>.section3 {
        padding: 0px 20px 40px 20px;
        border-color: #000000;
        margin: 20px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        max-width: 1200px;
    }
    .personal-info-page>.container>.section3>.details {
        width: 100%;
    }
    .personal-info-page>.container>.section3>.toast {
        width: 100%;
    }
}