.signup-page {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.signup-page>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.signup-page>.row1>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    flex: 1;
    border-style: solid;
    height: 100%;
}

.signup-page>.row1>.row2>.content {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.signup-page>.row1>.row2>.content>.logo-section {
    width: 100%;
    height: 20%;
    position: relative;
}

.signup-page>.row1>.row2>.content>.logo-section>.logo {
    width: 150px;
    height: 80px;
    margin: 24px 0px 0px 24px;
    border: none;
}

.signup-page>.row1>.row2>.content>.text-section {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.signup-page>.row1>.row2>.content>.text-section>.logotext {
    padding-top: 50px;
    width: 60%;
    height: auto;
}

.signup-page>.row1>.row2>.content>.playstore-buttons {
    width: 100%;
    display: flex;
    margin-top: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.signup-page>.row1>.row2>.content>.playstore-buttons>.storeimg {
    width: 120px;
    height: 50px;
    margin: 0px 12px;
    border: none;
}

.signup-page>.row1>.row2>.image3 {
    width: 100%;
    height: 100%;
}

.signup-page>.row1>.row4 {
    padding: 60px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    flex: 1;
    border-style: solid;
    height: 100%;
}

.signup-page>.row1>.row4>.playstore-buttons {
    display: none;
}

.signup-page>.row1>.row4>.row5 {
    padding: 0px 20px 0px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: none;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-page>.row1>.row4>.row5>.row13 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: none;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.signup-page>.row1>.row4>.row5>.row13>.image14 {
    height: 100%;
    width: 100px;
    height: 40px;
}

.signup-page>.row1>.row4>.row5>.row10 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-page>.row1>.row4>.row5>.row10>.u-i-component11 {
    width: 100%;
    max-width: 600px;
}

.signup-page>.row1>.row4>.row5>.row10>.u-i-component10 {
    width: 100%;
    max-width: 600px;
}

.signup-page>.row1>.row4>.row11 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-around;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .signup-page {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
        height: 100%;
    }
    .signup-page>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .signup-page>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: none;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        flex: 1;
        border-style: solid;
        height: 100%;
        position: relative;
    }
    .signup-page>.row1>.row2>.image3 {
        width: 100%;
        height: 100%;
    }
    .signup-page>.row1>.row4 {
        padding: 60px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        flex: 1;
        border-style: solid;
        height: 100%;
    }
    .signup-page>.row1>.row4>.playstore-buttons {
        display: flex;
        position: absolute;
        bottom: 10%;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-right: 24px;
    }
    .signup-page>.row1>.row4>.playstore-buttons>.storeimg {
        width: 100px;
        height: 40px;
        margin-right: 4px;
        border: none;
    }
    .signup-page>.row1>.row4>.row5 {
        padding: 0px 20px 0px 20px;
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-page>.row1>.row4>.row5>.row13 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .signup-page>.row1>.row4>.row5>.row13>.image14 {
        height: 100%;
    }
    .signup-page>.row1>.row4>.row5>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-page>.row1>.row4>.row5>.row10>.u-i-component11 {
        width: 100%;
    }
    .signup-page>.row1>.row4>.row5>.row10>.u-i-component10 {
        width: 100%;
    }
    .signup-page>.row1>.row4>.row11 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .signup-page {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
        height: 100%;
    }
    .signup-page>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .signup-page>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: none;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        flex: 1;
        border-style: solid;
        height: 100%;
        position: relative;
    }
    .signup-page>.row1>.row2>.image3 {
        width: 100%;
        height: 100%;
    }
    .signup-page>.row1>.row4 {
        padding: 60px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        flex: 1;
        border-style: solid;
        height: 100%;
    }
    .signup-page>.row1>.row4>.playstore-buttons {
        display: flex;
        position: absolute;
        bottom: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-right: 24px;
    }
    .signup-page>.row1>.row4>.playstore-buttons>.storeimg {
        width: 80px;
        height: 40px;
        margin-right: 4px;
        border: none;
    }
    .signup-page>.row1>.row4>.row5 {
        padding: 0px 20px 0px 20px;
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-page>.row1>.row4>.row5>.row13 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .signup-page>.row1>.row4>.row5>.row13>.image14 {
        height: 100%;
    }
    .signup-page>.row1>.row4>.row5>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-page>.row1>.row4>.row5>.row10>.u-i-component11 {
        width: 100%;
    }
    .signup-page>.row1>.row4>.row5>.row10>.u-i-component10 {
        width: 100%;
    }
    .signup-page>.row1>.row4>.row11 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
}