.mf-tooltip-container {
    display: flex;
    position: relative;
    align-self: center;
    width: fit-content;
}

.mf-tooltip-container>.tooltip-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mf-tooltip-container>.popup-tooltip {
    display: none;
    position: absolute;
    z-index: 100;
}

.mf-tooltip-container>.tooltipIcon>.icon {
    width: 100%;
    height: 100%;
}

.mf-tooltip-container>.popup-tooltip.active {
    display: block;
}

.mf-tooltip-container>.popup-tooltip>.popup-tooltip-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.mf-tooltip-container>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-title {
    white-space: nowrap;
    flex: 1;
    font-size: 20px;
    font-weight: 700;
    padding-left: 8px;
}

.mf-tooltip-container>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-close {
    display: flex;
    margin-left: auto;
}

.mf-tooltip-container>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-close>.popup-tooltip-close-icon {
    cursor: pointer;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.mf-tooltip-container>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-close>.popup-tooltip-close-icon {}

.mf-tooltip-container>.mf-tooltip-popup-arrow {
    display: none;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    cursor: pointer;
}

.mf-tooltip-container>.mf-tooltip-popup-arrow.active {
    display: flex;
}


/* ======================== sample ======================== */

.mf-tooltip-container.sample {}

.mf-tooltip-container.sample>.tooltip-icon {
    width: 50px;
    height: 50px;
    background-color: #6200ee;
    border-radius: 50%;
}

.mf-tooltip-container.sample>.tooltip-icon>.fallback-text {
    font-size: 18px;
    color: #ffffff;
}

.mf-tooltip-container.sample>.popup-tooltip {
    padding: 10px;
    width: 200px;
    height: 200px;
    background: #f5f5f5;
    color: #121212;
    border-radius: 4px;
    box-shadow: 1px 1px 3px #b6b6b6;
    border: 1px solid red;
}

.mf-tooltip-container.sample>.popup-tooltip>.popup-tooltip-header {
    border-bottom: 1px solid #BDC5C0;
    padding: 8px 12px;
}

.mf-tooltip-container.sample>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-title {
    font-size: 16px;
    font-weight: 600;
    color: #22252B;
}

.mf-tooltip-container.sample>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-close {
    width: 20px;
    height: 20px;
}

.mf-tooltip-container.sample>.mf-tooltip-popup-arrow {
    width: 10px;
    height: 10px;
    background: red;
}