
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* ============================================================== */


@font-face {
    font-family: "JosefinSans";
    font-weight: "100";
    src: url(../fonts/JosefinSans-Thin.ttf);
}

@font-face {
    font-family: "JosefinSans";
    font-weight: "200";
    src: url(../fonts/JosefinSans-ExtraLight.ttf);
}

@font-face {
    font-family: "JosefinSans";
    font-weight: "300";
    src: url(../fonts/JosefinSans-Light.ttf);
}

@font-face {
    font-family: "JosefinSans";
    font-weight: "400";
    src: url(../fonts/JosefinSans-Regular.ttf);
}

@font-face {
    font-family: "JosefinSans";
    font-weight: "500";
    src: url(../fonts/JosefinSans-Medium.ttf);
}

@font-face {
    font-family: "JosefinSans";
    font-weight: "600";
    src: url(../fonts/JosefinSans-SemiBold.ttf);
}

@font-face {
    font-family: "JosefinSans";
    font-weight: "700";
    src: url(../fonts/JosefinSans-Bold.ttf);
}

/* ============================================================== */


#app {
    font-family: Poppins;
}
