.tax-w8-b-e-n-form-v2 {
    background-color: #ffffff;
    border-color: #ffffff;
    align-items: flex-start;
    flex-direction: column;
    display: block;
    justify-content: space-between;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-n-form-v2>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-form-v2>.row1>.simple-listview31 {
    width: 50%;
}

.tax-w8-b-e-n-form-v2>.row1>.row35 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-form-v2>.row1>.row35>.button34 {
    margin: 6px 0px 0px 0px;
}

.tax-w8-b-e-n-form-v2>.w8benheader {
    font-size: 22px;
    font-weight: 700;
    margin-top: 24px;
}

.tax-w8-b-e-n-form-v2>.row36 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-form-v2>.row36>.u-i-component37 {
    width: 100%;
    height: 100%;
}

.tax-w8-b-e-n-form-v2>.row-title {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    margin: 16px 0px;
}

.tax-w8-b-e-n-form-v2>.row38 {
    border-color: #000000;
    originaldisplayvalue: flex;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-form-v2>.row38.part2>label {
    margin: 24px 0px;
}
.tax-w8-b-e-n-form-v2>.row38.part2> .regular-dropdown-default-span{
    display: inline-block;
    margin: 0px 12px;
}
.tax-w8-b-e-n-form-v2>.row38.part2> .regular-dropdown-default-span>.regular-dropdown-default-inline
{
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #f5f5f5;
}
.tax-w8-b-e-n-form-v2>.row38.part2>label>.mf-dropdown {
    width: 300px;
    margin: 4px 0px;
}

.tax-w8-b-e-n-form-v2>.row39 {
    border-color: #000000;
    margin: 24px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}


/* .tax-w8-b-e-n-form-v2>.row39>label {
    line-height: ;
} */

.tax-w8-b-e-n-form-v2>.row39>label>input {
    margin: 4px 8px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    background-color: #f5f5f5;
}

.tax-w8-b-e-n-form-v2>.row39>label>input.fullwidth {
    margin: 4px 0px;
    width: 100%;
}



/* .tax-w8-b-e-n-form-v2>.row39>label>input:nth-child(3) {
    width: 100%;
}

.tax-w8-b-e-n-form-v2>.row39>label>input:nth-child(4) {
    width: 100%;
} */

.tax-w8-b-e-n-form-v2>.row-title>.txname {
    margin: 16px 0px 0px 0px;
    width: 49%;
}

.tax-w8-b-e-n-form-v2>.row-title>.tx-date {
    margin: 16px 0px 0px 0px;
}

.tax-w8-b-e-n-form-v2>.buttons {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-n-form-v2>.buttons>.btn-back {
    width: 90px;
}

.tax-w8-b-e-n-form-v2>.buttons>.btn-next {
    margin: 0px 0px 0px 32px;
    width: 90px;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .tax-w8-b-e-n-form-v2 {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row1>.simple-listview31 {
        width: 100%;
    }
    .tax-w8-b-e-n-form-v2>.row1>.row35 {
        border-color: #000000;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .tax-w8-b-e-n-form-v2>.row36 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row36>.u-i-component37 {
        width: 100%;
        height: 100%;
    }
    .tax-w8-b-e-n-form-v2>.row-title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row38 {
        border-color: #000000;
        align-items: flex-start;
        originaldisplayvalue: flex;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2> .regular-dropdown-default-span{
        display: inline-block;
        margin: 0px 12px;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2> .regular-dropdown-default-span>.regular-dropdown-default-inline
    {
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: #f5f5f5;
    }
    .tax-w8-b-e-n-form-v2>.row39 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.buttons {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.buttons>.btn-back {
        width: 90px;
    }
    .tax-w8-b-e-n-form-v2>.buttons>.btn-next {
        margin: 0px 0px 0px 32px;
        width: 90px;
    }
    .tax-w8-b-e-n-form-v2>.row38 {
        border-color: #000000;
        originaldisplayvalue: flex;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2>label {
        margin: 12px 0px;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2>label>.mf-dropdown {
        width: 300px;
        margin: 4px 0px;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .tax-w8-b-e-n-form-v2 {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row1>.simple-listview31 {
        width: 100%;
    }
    .tax-w8-b-e-n-form-v2>.row1>.row35 {
        border-color: #000000;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .tax-w8-b-e-n-form-v2>.row36 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row36>.u-i-component37 {
        width: 100%;
        height: 100%;
    }
    .tax-w8-b-e-n-form-v2>.row-title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row38 {
        border-color: #000000;
        originaldisplayvalue: flex;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row39 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.buttons {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.buttons>.btn-back {
        width: 90px;
    }
    .tax-w8-b-e-n-form-v2>.buttons>.btn-next {
        margin: 0px 0px 0px 32px;
        width: 90px;
    }
    .tax-w8-b-e-n-form-v2>.row38 {
        border-color: #000000;
        originaldisplayvalue: flex;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2> .regular-dropdown-default-span{
        display: inline-block;
        margin: 0px 12px;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2> .regular-dropdown-default-span>.regular-dropdown-default-inline
    {
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: #f5f5f5;
    }
    
    .tax-w8-b-e-n-form-v2>.row38.part2>label {
        margin: 12px 0px;
    }
    .tax-w8-b-e-n-form-v2>.row38.part2>label>.mf-dropdown {
        width: 300px;
        margin: 4px 0px;
    }
}