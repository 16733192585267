
.sourceofwealth {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
}

.sourceofwealth>.row6 {
    border-color: rgba(154,155,158,1.00);
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-radius: 6px;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row2 {
    border-color: #000000;
    margin: 0px 0px 12px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row2>.regular-checkbox12 {
    width: 100%;
    height: 100%;
}

.sourceofwealth>.row6>.row8 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row8>.label9 {
    width: 160px;
}

.sourceofwealth>.row6>.row8>.regular-textbox10 {
    width: 100%;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.sourceofwealth {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
}

.sourceofwealth>.row6 {
    border-color: rgba(154,155,158,1.00);
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-radius: 6px;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row2 {
    border-color: #000000;
    margin: 0px 0px 12px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row2>.regular-checkbox12 {
    width: 100%;
    height: 100%;
}

.sourceofwealth>.row6>.row8 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row8>.label9 {
    width: 160px;
}

.sourceofwealth>.row6>.row8>.regular-textbox10 {
    width: 100%;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.sourceofwealth {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
}

.sourceofwealth>.row6 {
    border-color: rgba(154,155,158,1.00);
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-radius: 6px;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row2 {
    border-color: #000000;
    margin: 0px 0px 12px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row2>.regular-checkbox12 {
    width: 100%;
    height: 100%;
}

.sourceofwealth>.row6>.row8 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.sourceofwealth>.row6>.row8>.label9 {
    width: 160px;
}

.sourceofwealth>.row6>.row8>.regular-textbox10 {
    width: 100%;
}
}

