.cash-account {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.cash-account>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row1>.row7 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.cash-account>.row26 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3 {
    border-color: #000000;
    margin: 16px 0px 16px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row2 {
    padding: 0px 0px 0px 24px;
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-evenly;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 60px;
}

.cash-account>.row26>.row3>.row5 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row5>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row5>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    margin-bottom: 8px;
}

.cash-account>.row26>.row3>.row5>.row1>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row5>.row1>.row3>.row30 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row5>.row1>.row3>.row30>.label31 {
    width: 51px;
}
.cash-account>.row26>.row3>.row5>.row1>.row3>.label-form-error {
    padding-left: 45px;
}
.cash-account>.row26>.row3>.row5>.row1>.row3>.row30>.formatted-input-box15 {
    width: 100%;
}

.cash-account>.row26>.row3>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row6>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row6>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    margin-bottom: 8px;
}

.cash-account>.row26>.row3>.row6>.row1>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row6>.row1>.row3>.row30 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row6>.row1>.row3>.row30>.label31 {
    width: 51px;
}

.cash-account>.row26>.row3>.row6>.row1>.row3>.row30>.formatted-input-box15 {
    width: 100%;
}

.cash-account>.row26>.row3>.row6>.row1>.row3>.tb-account-number {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.cash-account>.row26>.row3>.row7 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row7>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row7>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    margin-bottom: 8px;
}

.cash-account>.row26>.row3>.row7>.row1>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row7>.row1>.row3>.row30 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row3>.row7>.row1>.row3>.row30>.label31 {
    width: 51px;
}

.cash-account>.row26>.row3>.row7>.row1>.row3>.row30>.formatted-input-box15 {
    width: 100%;
}

.cash-account>.row26>.row3>.row7>.row1>.row3>.tb-c-p-f-a-i-s-account-number {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.cash-account>.row26>.row8 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row9 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row9>.label11 {
    margin: 0px 4px 0px 0px;
}

.cash-account>.row26>.row8>.row10 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row1>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row1>.row3>.ccb-g-i-r-o {
    margin: 0px 0px 0px 12px;
}

.cash-account>.row26>.row8>.row10>.row12 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row12>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row12>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row12>.row1>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row12>.row1>.row3>.tb-e-p-s-account-number {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.cash-account>.row26>.row8>.row10>.row13 {
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    margin-top: 24px;
}

.cash-account>.row26>.row8>.row10>.row13>.instruction {
    margin-top: 12px;
}

.cash-account>.row26>.row8>.row10>.row13>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.dd-e-p-s-linkageto {
    width: 100%;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-giro-account-number {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-g-i-r-o-bank-branch {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-g-i-r-o-namein-bank-records {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-giro-bank-account-number {
    margin: 0px 0px 4px 0px;
    width: 100%;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .cash-account {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .cash-account>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row1>.row7 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .cash-account>.row26 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3 {
        border-color: #000000;
        margin: 16px 0px 16px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row2 {
        padding: 0px 0px 0px 24px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-evenly;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3>.row30 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3>.row30>.formatted-input-box15 {
        width: 100%;
    }
    .cash-account>.row26>.row3>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3>.tb-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row3>.row7 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3>.tb-c-p-f-a-i-s-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row9 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1>.row3>.ccb-g-i-r-o {
        margin: 0px 0px 0px 12px;
    }
    .cash-account>.row26>.row8>.row10>.row12 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1>.row3>.tb-e-p-s-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13 {
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-giro-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-g-i-r-o-bank-branch {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-g-i-r-o-namein-bank-records {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-giro-bank-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .cash-account {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .cash-account>.row1 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row1>.row7 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .cash-account>.row26 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3 {
        border-color: #000000;
        margin: 16px 0px 16px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row2 {
        padding: 0px 0px 0px 24px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-evenly;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        margin-bottom: 8px;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3>.row30 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3>.label-form-error {
        padding-left: 0px;
    }
    .cash-account>.row26>.row3>.row5>.row1>.row3>.row30>.formatted-input-box15 {
        width: 100%;
        letter-spacing: 5px;
    }
    .cash-account>.row26>.row3>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        margin-bottom: 8px;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3>.row30 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3>.label-form-error {
        padding-left: 0px;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3>.row30>.formatted-input-box15 {
        width: 100%;
        letter-spacing: 5px;
    }
    .cash-account>.row26>.row3>.row6>.row1>.row3>.tb-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row3>.row7 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        margin-bottom: 8px;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3>.row30 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3>.label-form-error {
        padding-left: 0px;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3>.row30>.formatted-input-box15 {
        width: 100%;
        letter-spacing: 5px;
    }
    .cash-account>.row26>.row3>.row7>.row1>.row3>.tb-c-p-f-a-i-s-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row9 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        margin-bottom: 8px;
    }
    .cash-account>.row26>.row8>.row10>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row1>.row3>.ccb-g-i-r-o {
        margin: 0px 0px 0px 12px;
    }
    .cash-account>.row26>.row8>.row10>.row12 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row12>.row1>.row3>.tb-e-p-s-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13 {
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-giro-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-g-i-r-o-bank-branch {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-g-i-r-o-namein-bank-records {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
    .cash-account>.row26>.row8>.row10>.row13>.row1>.row3>.tb-giro-bank-account-number {
        margin: 0px 0px 4px 0px;
        width: 100%;
    }
}