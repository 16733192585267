/* =================================================== */
/* horizondal stepper */

.mf-horizontal-stepper {
    display: flex;
    margin: 0;
    user-select: none;
    height: auto;
    justify-content: center;
    align-items: flex-start;
}

.mf-horizontal-stepper>.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.mf-horizontal-stepper>.step>.step-connector {
    position: absolute;
    content: "";
    left: 50%;
}

.mf-horizontal-stepper>.step>.step-outer-block {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mf-horizontal-stepper>.step>.step-outer-block>.step-inner-block {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* //========================================= */

.mf-horizontal-stepper.sample>.step {
    width: 120px;
    height: 32px;
}

.mf-horizontal-stepper.sample>.step>.step-connector {
    border-bottom: 1px solid #ccc;
}

.mf-horizontal-stepper.sample>.step>.step-connector.active {
    border-bottom: 2px solid #8fca0f;
}

.mf-horizontal-stepper.sample>.step>.step-outer-block {
    padding: 8px;
    border: 4px solid #00000000;
}

.mf-horizontal-stepper.sample>.step>.step-outer-block.active {
    border: 4px solid #8fca0f;
}

.mf-horizontal-stepper.sample>.step>.step-outer-block>.step-inner-block {
    color: #f5f5f5;
    font-size: 18px;
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    background-color: #ccc;
}

.mf-horizontal-stepper.sample>.step>.step-outer-block>.step-inner-block.active {
    border: 1px solid #8fca0f;
    background-color: #8fca0f;
    color: #fff;
    font-weight: 600;
}

.mf-horizontal-stepper.sample>.step>.step-text {
    color: rgba(14, 50, 93, 0.4);
    padding: 8px 0px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.mf-horizontal-stepper.sample>.step>.step-text.active {
    color: #8fca0f;
}

/* =================================================== */
/* horizondal stepper with icon */

.mf-horizontal-stepper-with-icon {
    display: flex;
    margin: 0;
    user-select: none;
    height: auto;
    justify-content: center;
    align-items: flex-start;
}

.mf-horizontal-stepper-with-icon>.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.mf-horizontal-stepper-with-icon>.step>.step-connector {
    position: absolute;
    content: "";
    left: 50%;
}

.mf-horizontal-stepper-with-icon>.step>.step-connector.active::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 0px;
    border-radius: 10px;
    animation-name: stepperWidthAnim;
    animation-duration: 1s;
    animation-iteration-count: linear;
    animation-timing-function: ease-in;
}


.mf-horizontal-stepper-with-icon>.step>.step-connector.active-reverse {
    display: flex;
    justify-content: flex-end;
}

.mf-horizontal-stepper-with-icon>.step>.step-connector.active-reverse::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0px;
    border-radius: 10px;
    animation-name: stepperWidthAnim;
    animation-duration: 1s;
    animation-iteration-count: linear;
    animation-timing-function: ease-in;
    /* animation-direction: reverse; */
}

.mf-horizontal-stepper-with-icon>.step>.step-outer-block {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.8s;

}

.mf-horizontal-stepper-with-icon>.step>.step-outer-block.active {
    transition-delay: 0.7s;
}

.mf-horizontal-stepper-with-icon>.step>.step-outer-block>.step-inner-block {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.8s, border 0.8s, color 0.8s;
}

.mf-horizontal-stepper-with-icon>.step>.step-outer-block.active>.step-inner-block {
    transition-delay: 0.7s;
}

@keyframes stepperWidthAnim {
    0% {
        width: 0px;
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        width: 100%;
        opacity: 0;
    }
}

/* //========================================= */

.mf-horizontal-stepper-with-icon.sample>.step {
    width: 150px;
    height: 32px;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-connector {
    border-bottom: 2px solid #000000;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-connector.active {
    border-bottom: 2px solid #000000;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-connector.active::before {
    background: #ffbc00;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-connector.active-reverse::after {
    background: #ffbc00;
}


.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block {
    padding: 8px;
    border: 2px solid #00000000;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block.active {
    border: 2px solid #ffbc00;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block.completed {
    border: 2px solid #000000;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block>.step-inner-block {
    color: #f5f5f5;
    font-size: 18px;
    width: 60px;
    height: 60px;
    border: 1px solid #000000;
    background-color: #000000;
    font-weight: bold;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block>.step-inner-block.active {
    border: 1px solid #ffbc00;
    background-color: #ffbc00;
    color: #000000;
    font-weight: bold;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block>.step-inner-block.completed {
    border: 1px solid #000000;
    background-color: #000000;
    color: #f5f5f5;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>svg,
.mf-horizontal-stepper-with-icon.sample>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>img {
    width: 24px;
    height: 24px;
    fill: #ffbc00;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-text {
    color: #aaaaaa;
    padding: 8px 0px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-text.active {
    color: #000000;
}

.mf-horizontal-stepper-with-icon.sample>.step>.step-text.completed {
    color: #ffbc00;
}