.signup-phone {
    padding: 20px 20px 20px 20px;
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    position: relative;
}

.signup-phone>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-phone>.row1>.row5 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-phone>.row1>.row5>.btn-signup-phone {
    margin: 0px 0px 0px 5px;
    display: block;
}

.signup-phone>.row1>.row5>.btn-signup-phonesmall {
    margin: 0px 0px 0px 5px;
    display: none;
}

.signup-phone>.row1>.row6 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-phone>.row1>.row6>.mobilenumber {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.signup-phone>.row1>.row6>.changemobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown19 {
    width: 100px !important;
}

.signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown19>.mf-dropdown-select-options {
    width: 200px;
}

.signup-phone>.row1>.row6>.mobilenumber>.tb-email {
    width: 100%;
    border-radius: 0px 4px 4px 0px;
}

.signup-phone>.row1>.row6>.verifyblock {
    width: 100%;
    position: relative;
}

.signup-phone>.row1>.row6>.verifyblock>.tb-verifcation-code {
    margin: 8px 0px 0px 0px;
    width: 100%;
}

.signup-phone>.row1>.row6>.verifyblock>.row-sendcode {
    position: absolute;
    right: 4px;
    top: 12px;
}

.signup-phone>.row1>.row6>.verifyblock>.row-sendcode>button {
    background: #5f8ccf !important;
    color: #ffff;
    padding: 10px 12px;
    border-radius: 4px;
}

.signup-phone>.row1>.row6>.verifyblock>.row-sendcode>button:disabled {
    background: #f8f8f8 !important;
    color: #ccc;
}

.signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown-country-list {
    padding: 12px 4px 11px 4px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px 0px 0px 4px;
    theme-color: #ffffff;
    border-width: 1px 0px 1px 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    width: 90px;
}

.signup-phone>.row1>.row15 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-end;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-phone>.row1>.row15>.row-timer {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.signup-phone>.row1>.row15>.row-sendcode {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.signup-phone>.row1>.row15>.row-re-sendcode {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.signup-phone>.row1>.row16 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 20px;
}

.signup-phone>.row1>.btn-next {
    margin: 24px 0px 0px 0px;
    width: 100px;
}

.signup-phone>.row1>.row10 {
    border-color: #000000;
    margin: 24px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    align-items: center;
}

.signup-phone>.row1>.row10>.label11 {
    /* width: 300px; */
}

.signup-phone>.row1>.row10>.row24 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.signup-phone>.row1>.row10>.row24>.hyperlink25 {
    margin: 0px 0px 4px 0px;
}

.signup-phone>.row1>.row10>.row24>.hyperlink26 {
    margin: 0px 0px 4px 0px;
}

.signup-phone>.row1>.row10>.row24>.hyperlink27 {
    margin: 0px 0px 4px 0px;
}

.signup-phone>.row22 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.signup-phone>.row22>.u-i-component23 {
    width: 100%;
}

.signup-phone>.row11 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.signup-phone>.login-options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    flex-direction: column;
    margin-top: 12px;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .signup-phone {
        padding: 20px 20px 20px 20px;
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row5 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row5>.btn-signup-phone {
        margin: 0px 0px 0px 5px;
        display: block;
    }
    .signup-phone>.row1>.row5>.btn-signup-phonesmall {
        margin: 0px 0px 0px 5px;
        display: none;
    }
    .signup-phone>.row1>.row6 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row6>.mobilenumber {
        border-color: #000000;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown19 {
        width: auto;
        padding: 11px 16px;
    }
    .signup-phone>.row1>.row6>.mobilenumber>.tb-email {
        width: 100%;
    }
    .signup-phone>.row1>.row6>.verifyblock {
        width: 100%;
        position: relative;
    }
    .signup-phone>.row1>.row6>.verifyblock>.tb-verifcation-code {
        margin: 8px 0px 0px 0px;
        width: 100%;
    }
    .signup-phone>.row1>.row6>.verifyblock>.row-sendcode {
        position: absolute;
        right: 4px;
        top: 12px;
    }
    .signup-phone>.row1>.row6>.verifyblock>.row-sendcode>button {
        background: #5f8ccf !important;
        color: #ffff;
        padding: 10px 12px;
        border-radius: 4px;
    }
    .signup-phone>.row1>.row6>.verifyblock>.row-sendcode>button:disabled {
        background: #f8f8f8 !important;
        color: #ccc;
    }
    .signup-phone>.row1>.row15 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-end;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row15>.row-timer {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row15>.row-sendcode {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row15>.row-re-sendcode {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row16 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 20px;
    }
    .signup-phone>.row1>.btn-next {
        margin: 24px 0px 0px 0px;
        width: 100px;
    }
    .signup-phone>.row1>.row10 {
        border-color: #000000;
        margin: 24px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row10>.row24 {
        border-color: #000000;
        margin: 8px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row10>.row24>.hyperlink25 {
        margin: 0px 0px 4px 0px;
    }
    .signup-phone>.row1>.row10>.row24>.hyperlink26 {
        margin: 0px 0px 4px 0px;
    }
    .signup-phone>.row1>.row10>.row24>.hyperlink27 {
        margin: 0px 0px 4px 0px;
    }
    .signup-phone>.row22 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row22>.u-i-component23 {
        width: 100%;
    }
    .signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown19 {
        width: 120px !important;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .signup-phone {
        padding: 20px 20px 20px 20px;
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown19 {
        width: 120px !important;
    }
    .signup-phone>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row5 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row5>.btn-signup-phone {
        margin: 0px 0px 0px 5px;
        display: none;
    }
    .signup-phone>.row1>.row5>.btn-signup-phonesmall {
        margin: 0px 0px 0px 5px;
        display: block;
    }
    .signup-phone>.row1>.row6 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row6>.mobilenumber {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row6>.mobilenumber>.regular-dropdown19 {
        width: 100px;
        padding: 12px 16px;
    }
    .signup-phone>.row1>.row6>.mobilenumber>.tb-email {
        width: 100%;
    }
    .signup-phone>.row1>.row6>.verifyblock {
        width: 100%;
        position: relative;
    }
    .signup-phone>.row1>.row6>.verifyblock>.tb-verifcation-code {
        margin: 8px 0px 0px 0px;
        width: 100%;
    }
    .signup-phone>.row1>.row6>.verifyblock>.row-sendcode {
        position: absolute;
        right: 4px;
        top: 12px;
    }
    .signup-phone>.row1>.row6>.verifyblock>.row-sendcode>button {
        background: #5f8ccf !important;
        color: #ffff;
        padding: 10px 12px;
        border-radius: 4px;
    }
    .signup-phone>.row1>.row6>.verifyblock>.row-sendcode>button:disabled {
        background: #f8f8f8 !important;
        color: #ccc;
    }
    .signup-phone>.row1>.row15 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-end;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row15>.row-timer {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row15>.row-sendcode {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row15>.row-re-sendcode {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row1>.row16 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 20px;
    }
    .signup-phone>.row1>.btn-next {
        margin: 24px 0px 0px 0px;
        width: 100px;
    }
    .signup-phone>.row1>.row10 {
        border-color: #000000;
        margin: 24px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row10>.row24 {
        border-color: #000000;
        margin: 8px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .signup-phone>.row1>.row10>.row24>.hyperlink25 {
        margin: 0px 0px 4px 0px;
    }
    .signup-phone>.row1>.row10>.row24>.hyperlink26 {
        margin: 0px 0px 4px 0px;
    }
    .signup-phone>.row1>.row10>.row24>.hyperlink27 {
        margin: 0px 0px 4px 0px;
    }
    .signup-phone>.row22 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        border-style: solid;
    }
    .signup-phone>.row22>.u-i-component23 {
        width: 100%;
    }
}