
.user-type-selection-page {
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.user-type-selection-page>.row3 {
    border-color: #000000;
    align-items: center;
    background-image: url("../../images/logos/tigerbackground.png");
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    background-size: auto 100%;
    background-position: right;
    border-style: solid;
    background-repeat: no-repeat;
    height: 100%;
}

.user-type-selection-page>.row3>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-page>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.user-type-selection-page>.row3>.row5 {
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 800px;
}

.user-type-selection-page>.row3>.row5>.u-i-component7 {
    width: 100%;
    height: 100%;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.user-type-selection-page {
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.user-type-selection-page>.row3 {
    border-color: #000000;
    align-items: center;
    background-image: url("../../images/logos/tigerbackground.png");
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    background-size: auto 100%;
    background-position: right;
    border-style: solid;
    background-repeat: no-repeat;
    height: 100%;
}

.user-type-selection-page>.row3>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-page>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.user-type-selection-page>.row3>.row5 {
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 800px;
}

.user-type-selection-page>.row3>.row5>.u-i-component7 {
    width: 100%;
    height: 100%;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.user-type-selection-page {
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.user-type-selection-page>.row3 {
    border-color: #000000;
    align-items: center;
    background-image: url("../../images/logos/tigerbackground.png");
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    background-size: auto 100%;
    background-position: right;
    border-style: solid;
    background-repeat: no-repeat;
    height: 100%;
}

.user-type-selection-page>.row3>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-page>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.user-type-selection-page>.row3>.row5 {
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 800px;
}

.user-type-selection-page>.row3>.row5>.u-i-component7 {
    width: 100%;
    height: 100%;
}
}

