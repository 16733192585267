.c-a-r-formsection2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.c-a-r-formsection2>.section1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row1>.cell1>.lb-customer-qualification {
    width: 100%;
}

.c-a-r-formsection2>.section1>.row1>.cell1>.dd-customer-qualification {
    margin: 8px 0px 2px 0px;
    width: 49%;
}

.c-a-r-formsection2>.section1>.row6 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row6>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row6>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row6>.row1>.cell1>.tb-select-qualifications1 {
    margin: 0px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.row6>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1>.tb-nameof-intuitions1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1>.tb-yearof-graduations1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row1>.cell1>.dd-professional-qualifications2 {
    margin: 8px 0px 2px 0px;
    width: 49%;
}

.c-a-r-formsection2>.section1>.section2>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row6>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-professional-certifications2 {
    margin: 0px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-nameof-intuitions2 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-yearof-graduation {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row1 {
    background-color: rgba(88, 185, 80, 0);
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1>.cell1>.dd-work-experiences3 {
    margin: 8px 0px 2px 0px;
    width: 49%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1 {
    background-color: rgba(131, 185, 126, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-selectyourexpreriences3_copy {
    margin: 0px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-nameof-companys3_copy {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-periodof-services3_copy {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    margin-top: 24px;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.lb-contractsfor-differences4_copy {
    margin: 24px 0px 0px 0px;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1 {
    background-color: rgba(88, 185, 80, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1>.cell1>.dd-contractsfor-differences4 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6 {
    background-color: rgba(112, 163, 108, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1 {
    background-color: rgba(131, 185, 126, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1>.tb-c-f-d-nameof-investment-products4 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1>.tb-c-f-d-most-recent-yearof-transactions4 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1 {
    background-color: rgba(88, 185, 80, 0);
    border-color: #000000;
    /* margin: 16px 0px 0px 0px; */
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1>.cell1>.dd-l-f-x-leveraged-foreign-exchanges4 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6 {
    background-color: rgba(112, 163, 108, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1 {
    background-color: rgba(131, 185, 126, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1>.tb-l-f-x-nameof-investment-products4 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1>.tb-l-f-x-most-recent-yearof-transactions4 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.section5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-formsection2>.section1>.section2>.section3>.section4>.section5>.u-i-component8 {
    width: 100%;
    height: 100%;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .c-a-r-formsection2 {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .c-a-r-formsection2>.section1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row1>.cell1>.dd-customer-qualification {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.cell1>.tb-select-qualifications1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1>.tb-nameof-intuitions1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1>.tb-yearof-graduations1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row1>.cell1>.dd-professional-qualifications2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-professional-certifications2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-nameof-intuitions2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-yearof-graduation {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1>.cell1>.dd-work-experiences3 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1 {
        background-color: rgba(131, 185, 126, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-selectyourexpreriences3_copy {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-nameof-companys3_copy {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-periodof-services3_copy {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.lb-contractsfor-differences4_copy {
        margin: 24px 0px 0px 0px;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1>.cell1>.dd-contractsfor-differences4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1 {
        background-color: rgba(131, 185, 126, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1>.tb-c-f-d-nameof-investment-products4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1>.tb-c-f-d-most-recent-yearof-transactions4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1>.cell1>.dd-l-f-x-leveraged-foreign-exchanges4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1 {
        background-color: rgba(131, 185, 126, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1>.tb-l-f-x-nameof-investment-products4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1>.tb-l-f-x-most-recent-yearof-transactions4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.section5 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.section5>.u-i-component8 {
        width: 100%;
        height: 100%;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .c-a-r-formsection2 {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .c-a-r-formsection2>.section1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row1>.cell1>.dd-customer-qualification {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.cell1>.tb-select-qualifications1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1>.tb-nameof-intuitions1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.row6>.row1>.row1>.cell1>.tb-yearof-graduations1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row1>.cell1>.dd-professional-qualifications2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-professional-certifications2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-nameof-intuitions2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.row6>.row1>.row1>.cell1>.tb-yearof-graduation {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row1>.row1>.cell1>.dd-work-experiences3 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1 {
        background-color: rgba(131, 185, 126, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-selectyourexpreriences3_copy {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-nameof-companys3_copy {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.row6>.row1>.row1_copy>.cell1_copy>.tb-periodof-services3_copy {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.lb-contractsfor-differences4_copy {
        margin: 24px 0px 0px 0px;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row1>.row1>.cell1>.dd-contractsfor-differences4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1 {
        background-color: rgba(131, 185, 126, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1>.tb-c-f-d-nameof-investment-products4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.c-f-d>.row6>.row1>.row1>.cell1>.tb-c-f-d-most-recent-yearof-transactions4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1 {
        background-color: rgba(88, 185, 80, 0);
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row1>.row1>.cell1>.dd-l-f-x-leveraged-foreign-exchanges4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6 {
        background-color: rgba(112, 163, 108, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1 {
        background-color: rgba(131, 185, 126, 0);
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1>.tb-l-f-x-nameof-investment-products4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.l-f-x>.row6>.row1>.row1>.cell1>.tb-l-f-x-most-recent-yearof-transactions4 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.section5 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-a-r-formsection2>.section1>.section2>.section3>.section4>.section5>.u-i-component8 {
        width: 100%;
        height: 100%;
    }
}