/* ================================================ */
/* Radios */

.mf-radios {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.mf-comp-radiobutton {
    position: relative;
}

.mf-comp-radiobutton>[type="radio"]:checked, .mf-comp-radiobutton>[type="radio"]:not(:checked) {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0px;
    z-index: 2;
    cursor: pointer;
}

.mf-comp-radiobutton>[type="radio"]:checked+label, .mf-comp-radiobutton>[type="radio"]:not(:checked)+label {
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: visible !important;
}

.mf-comp-radiobutton>[type="radio"]:checked+label>.check, .mf-comp-radiobutton>[type="radio"]:not(:checked)+label>.check {
    position: relative;
}

.mf-comp-radiobutton>[type="radio"]:checked+label>.check>.after-holder, .mf-comp-radiobutton>[type="radio"]:not(:checked)+label>.check>.after-holder {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}

.mf-comp-radiobutton>[type="radio"]:not(:checked)+label>.check>.after-holder {
    opacity: 0;
    transform: scale(0);
}

.mf-comp-radiobutton>[type="radio"]:checked+label>.check>.after-holder {
    opacity: 1;
    transform: scale(1);
}

.mf-radios>.mf-comp-radiobutton>label>.check>.before>.mf-ripple-block {
    border-radius: 50%;
    z-index: 3;
}

/* ===================================================== */
/* sample */

.mf-radios.sample {
    flex-direction: column;
}

.mf-radios.sample>.mf-comp-radiobutton {
    margin-bottom: 16px;
}

.mf-radios.sample>.mf-comp-radiobutton>label {
    white-space: normal;
    overflow: hidden;
    color: #121212;
    text-align: left;
    font-size: 16px;
    text-decoration: unset;
    text-overflow: unset;
    font-style: normal;
    font-weight: 400;
}

.mf-radios.sample>.mf-comp-radiobutton>label>.check>.before {
    background-color: rgba(240, 240, 240, 0);
    border-radius: 12px;
    width: 24px;
    border: 1px solid #aaa;
    height: 24px;
}

.mf-radios.sample>.mf-comp-radiobutton>label>.check>.before>.mf-ripple-block {
    width: 48px;
    height: 48px;
    left: -12px;
    top: -12px;
}

.mf-radios.sample>.mf-comp-radiobutton>label>.check>.before>.mf-ripple-block>.ripple {
    background-color: rgba(75, 75, 214, 0.3);
}

.mf-radios.sample>.mf-comp-radiobutton>label>.check>.after-holder>.after {
    background-color: #4b4bd6;
    border-radius: 8px;
    width: 12px;
    border: 0 solid #1f1d1d;
    height: 12px;
}

.mf-radios.sample>.mf-comp-radiobutton>label>.title {
    margin-left: 8px;
}

.mf-radios.sample>.mf-comp-radiobutton>label>.title>.icon {
    width: 30px;
    height: 20px;
    margin-right: 10px;
}