
.tax-w8-b-e-npage {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-npage>.row3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-npage>.row3>.row4 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-npage>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.tax-w8-b-e-npage>.row3>.row8 {
    padding: 20px 0px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-npage>.row3>.row5 {
    padding: 0px 20px 40px 20px;
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-npage>.row3>.row5>.u-i-component7 {
    width: 100%;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.tax-w8-b-e-npage {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-npage>.row3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-npage>.row3>.row4 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-npage>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.tax-w8-b-e-npage>.row3>.row8 {
    padding: 20px 0px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-npage>.row3>.row5 {
    padding: 0px 20px 40px 20px;
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-npage>.row3>.row5>.u-i-component7 {
    width: 100%;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.tax-w8-b-e-npage {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-npage>.row3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.tax-w8-b-e-npage>.row3>.row4 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-w8-b-e-npage>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.tax-w8-b-e-npage>.row3>.row8 {
    padding: 20px 0px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-npage>.row3>.row5 {
    padding: 0px 20px 40px 20px;
    border-color: #000000;
    margin: 20px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.tax-w8-b-e-npage>.row3>.row5>.u-i-component7 {
    width: 100%;
}
}

