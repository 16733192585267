/* ================================================ */
/* Checkboxes */

.mf-checkboxes {
    display: flex;
    flex-wrap: wrap;
}

.mf-comp-checkbox {
    position: relative;
}

.mf-comp-checkbox>label {
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: visible !important;
}

.mf-comp-checkbox>label>.check {
    position: relative;
}

.mf-comp-checkbox>label>.check>.after-holder {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}

.mf-comp-checkbox>label>.check>.after-holder>.after {
    background-color: transparent;
    width: 80%;
    height: 30%;
    border-width: 0px 0px 3px 3px;
    border-style: solid;
}

.mf-comp-checkbox>label>.check>.after-holder {
    opacity: 0;
    transform: scale(0);
}

.mf-comp-checkbox.checked>label>.check>.after-holder {
    opacity: 1;
    transform: scale(1) translate(8%, -6%);
}

.mf-comp-checkbox>label>.check>.before {
    position: relative;
}

.mf-checkboxes>.mf-comp-checkbox>label>.check>.before>.mf-ripple-block {
    border-radius: 50%;
    z-index: 3;
}

/* ========================================================= */
/* sample */

.mf-checkboxes.sample {
    flex-direction: column;
}

.mf-checkboxes.sample>.mf-comp-checkbox {
    margin-bottom: 8px;
}

.mf-checkboxes.sample>.mf-comp-checkbox>label>.title {
    margin-left: 8px;
}

.mf-checkboxes.sample>.mf-comp-checkbox>label {
    color: rgb(18, 18, 18);
    font-size: 16px;
    text-decoration: unset;
    font-style: normal;
}

.mf-checkboxes.sample>.mf-comp-checkbox>label>.check {
    background-color: #ffffff;
    border-color: #a7a7a7;
    border-radius: 3px;
    border-width: 1px;
    width: 20px;
    border-style: solid;
    height: 20px;
}

.mf-checkboxes.sample>.mf-comp-checkbox>label>.check>.after-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mf-checkboxes.sample>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 14px;
    fill: #6200ee;
    height: 14px;
}