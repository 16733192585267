
.user-type-selection-pagev2 {
    padding: 0px 0px 12px 0px;
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row2 {
    padding: 0px 0px 0px 24px;
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.user-type-selection-pagev2>.row1>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5 {
    padding: 24px 24px 24px 24px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 50%;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row7 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row7>.image9 {
    margin: 8px 0px 0px 8px;
    width: 160px;
    height: 30px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row10 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row11 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row12>.image-button23 {
    width: 80%;
    height: 43px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row14 {
    border-color: #000000;
    margin: 32px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row14>.label16 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row17 {
    border-color: #000000;
    margin: 26px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row19 {
    background-color: rgba(188, 211, 186, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 4px;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row6 {
    padding: 24px 24px 24px 24px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 50%;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row7 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row10 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row10>.label11_copy {
    margin: 12px 0px 0px 0px;
    width: 387px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.button21 {
    margin: 28px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14 {
    border-color: #000000;
    margin: 32px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label16 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label17 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label18 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label19 {
    padding: 0px 0px 0px 12px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label20 {
    padding: 0px 0px 0px 12px;
}

.user-type-selection-pagev2>.row1>.row5 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.user-type-selection-pagev2 {
    padding: 0px 0px 12px 0px;
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row2 {
    padding: 0px 0px 0px 24px;
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.user-type-selection-pagev2>.row1>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5 {
    padding: 24px 24px 24px 24px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row7 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row7>.image9 {
    margin: 8px 0px 0px 8px;
    width: 160px;
    height: 30px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row10 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row10>.label11 {
    width: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row11 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row11>.label12 {
    width: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row12>.image-button23 {
    width: 80%;
    height: 43px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row14 {
    border-color: #000000;
    margin: 32px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row14>.label16 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row17 {
    border-color: #000000;
    margin: 26px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row19 {
    background-color: rgba(188, 211, 186, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 4px;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row6 {
    padding: 24px 24px 24px 24px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row7 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row10 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row10>.label11_copy {
    width: 387px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.button21 {
    margin: 28px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14 {
    border-color: #000000;
    margin: 32px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label16 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label17 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label18 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label19 {
    padding: 0px 0px 0px 12px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label20 {
    padding: 0px 0px 0px 12px;
}

.user-type-selection-pagev2>.row1>.row5 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.user-type-selection-pagev2 {
    padding: 0px 0px 12px 0px;
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row2 {
    padding: 0px 0px 0px 24px;
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.user-type-selection-pagev2>.row1>.row4 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5 {
    padding: 24px 24px 24px 24px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row7 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row7>.image9 {
    margin: 8px 0px 0px 8px;
    width: 140px;
    height: 26px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row10 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row10>.label11 {
    width: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row11 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row11>.label12 {
    width: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row12>.image-button23 {
    width: 80%;
    height: 43px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row14 {
    border-color: #000000;
    margin: 32px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row14>.label16 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row5>.row17 {
    border-color: #000000;
    margin: 26px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row19 {
    background-color: rgba(188, 211, 186, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 4px;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row6 {
    padding: 24px 24px 24px 24px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row7 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row10 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row10>.label11_copy {
    width: 387px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.button21 {
    margin: 28px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14 {
    border-color: #000000;
    margin: 32px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label16 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label17 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label18 {
    margin: 8px 0px 0px 0px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label19 {
    padding: 0px 0px 0px 12px;
}

.user-type-selection-pagev2>.row1>.row4>.row6>.row14>.label20 {
    padding: 0px 0px 0px 12px;
}

.user-type-selection-pagev2>.row1>.row5 {
    border-color: #000000;
    margin: 0px 0px 16px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}
}

