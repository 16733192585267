.mf-timer-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ============== sample =============== */

.mf-timer-container.sample>.clock,
.mf-timer-container.sample>.splitter {
    margin-right: 8px;
    padding: 12px;
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 10px;
    color: #121212;
}