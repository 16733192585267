.singleselection-checkbox {
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    /* width: 90%; */
    width: 100%;
}

.singleselection-checkbox>.section11 {
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    width: 100%;
}

.singleselection-checkbox>.section11>.grade-based {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    width: 48%;
}

.singleselection-checkbox>.section11>.flat-rate {
    align-items: center;
    flex-direction: row;
    display: flex;
    width: 48%;
    justify-content: flex-start;
}

/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .singleselection-checkbox {
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .singleselection-checkbox>.section11 {
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        width: 100%;
    }

    .singleselection-checkbox>.section11>.grade-based {
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .singleselection-checkbox>.section11>.flat-rate {
        align-items: center;
        flex-direction: row;
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
}

/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .singleselection-checkbox {
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .singleselection-checkbox>.section11 {
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        width: 100%;
    }

    .singleselection-checkbox>.section11>.grade-based {
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .singleselection-checkbox>.section11>.flat-rate {
        align-items: center;
        flex-direction: row;
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
}