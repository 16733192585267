.regular-o-t-pboxes-default.disabled>input {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    theme-color: rgba(98, 0, 238, 1.00);
    border-width: 1px;
    width: 30px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
    height: 40px;
}

.regular-o-t-pboxes-default:focus-within>input {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(98, 0, 238, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    theme-color: rgba(98, 0, 238, 1.00);
    border-width: 1px;
    width: 30px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    height: 40px;
}

.regular-o-t-pboxes-default>input {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    theme-color: rgba(98, 0, 238, 1.00);
    border-width: 1px;
    width: 30px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    height: 40px;
}

.regular-o-t-pboxes-default.error>input {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    theme-color: rgba(98, 0, 238, 1.00);
    border-width: 1px;
    width: 30px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    height: 40px;
}

.regular-o-t-pboxes-default:hover>input {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(166, 166, 166, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    theme-color: rgba(98, 0, 238, 1.00);
    border-width: 1px;
    width: 30px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    height: 40px;
}

.app {
    background-color: rgba(254, 254, 254, 1.00);
    theme-color: rgba(255, 188, 0, 1.00);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 188, 0, 1.00);
}

.label-default {
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.label-header {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 22px;
    font-weight: bold;
}

.label-regular-center {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.label-form-label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.label-form-italic {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 13px;
    font-style: italic;
    font-weight: normal;
}

.label-form-error {
    overflow: hidden;
    font-family: inherit;
    color: rgba(223, 0, 0, 1.00);
    text-align: left;
    font-size: 12px;
    font-weight: normal;
}

.label-signup-header {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 16px;
    font-weight: normal;
}

.label-signup-headersmall {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 12px;
    font-weight: normal;
}

.label-title {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}

.label-description-title {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.label-sub-section-title {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.label-important {
    overflow: hidden;
    font-family: inherit;
    color: rgba(223, 0, 0, 1.00);
    text-align: left;
    font-size: 12px;
    font-weight: normal;
}

.label-important.red {
    margin-left: 4px;
    margin-bottom: 4px;
}

.label-larger-22px {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 22px;
    font-weight: 600;
}

.label-description14px {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    text-overflow: clip;
    font-weight: 500;
}

.label-header-30px-semibold {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 30px;
    font-weight: 600;
}

.label-description16px {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 16px;
    text-overflow: clip;
    font-weight: 600;
}

.label-header-50px-800 {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 50px;
    font-weight: 800;
}

.image-default {
    border-color: rgba(0, 0, 0, 1.00);
    border-width: 0px;
    border-style: solid;
}

button.button-default:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(235, 235, 235, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: #000000;
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-default {
    padding: 8px 16px 8px 16px;
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-default:disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

button.button-default:active {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: #000000;
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-primary {
    padding: 8px 16px 8px 16px;
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-primary:active {
    padding: 8px 16px 8px 16px;
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-primary:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-primary:disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(221, 222, 223, 1.00);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

button.button-secondary:active {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-secondary:disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

button.button-secondary:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-secondary {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-back:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-back:disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

button.button-back:active {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-back {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

button.button-style1:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
}

button.button-style1:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: bold;
}

button.button-style1 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(0, 113, 186, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
}

button.button-style1:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
}

button.button-style2:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 10px;
    border-style: solid;
    font-weight: bold;
}

button.button-style2 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(0, 113, 186, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 10px;
    border-style: solid;
    font-weight: bold;
}

button.button-style2:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 10px;
    border-style: solid;
    font-weight: bold;
}

button.button-style2:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 10px;
    opacity: 40%;
    border-style: solid;
    font-weight: bold;
}

button.button-style3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(0, 113, 186, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
}

button.button-style3:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

button.button-style3:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
}

button.button-style3:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
}

button.button-style4 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(0, 113, 186, 1.00);
    text-align: left;
    text-decoration: underline;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
}

button.button-style4:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
}

button.button-style4:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
}

button.button-style4:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

button.button-blue-button:active {
    padding: 8px 16px 8px 16px;
    background-color: rgba(95, 181, 189, 1.00);
    border-color: rgba(193, 226, 230, 1.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 2px;
    font-size: 18px;
    border-style: solid;
    font-weight: 500;
}

button.button-blue-button {
    padding: 8px 16px 8px 16px;
    background-color: rgba(73, 176, 186, 1.00);
    border-color: rgba(193, 226, 230, 0.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 2px;
    font-size: 18px;
    border-style: solid;
    font-weight: 500;
}

button.button-blue-button:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(106, 142, 145, 1.00);
    border-color: rgba(193, 226, 230, 0.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 2px;
    font-size: 18px;
    border-style: solid;
    font-weight: 500;
}

button.button-blue-button:disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(73, 176, 186, 1.00);
    border-color: rgba(193, 226, 230, 1.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 2px;
    font-size: 18px;
    border-style: solid;
    font-weight: 500;
}

button.button-style5:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
}

button.button-style5 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(118, 126, 131, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
}

button.button-style5:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(221, 222, 223, 1.00);
    color: rgba(154, 155, 158, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: bold;
}

button.button-style5:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-width: 0px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
}

button.button-style6 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(0, 113, 186, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style6:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style6:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style6:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(221, 221, 221, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style7 {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(118, 126, 131, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style7:active {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style7:hover {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(254, 195, 47, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-style7:disabled {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(221, 221, 221, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 12px;
    border-style: solid;
    font-weight: 600;
}

button.button-verify:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 188, 0, 0.72);
    border-color: rgba(193, 226, 230, 0.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 9px;
    border-width: 2px;
    font-size: 14px;
    border-style: solid;
    font-weight: 500;
}

button.button-verify:active {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 188, 0, 1.00);
    border-color: rgba(193, 226, 230, 0.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 9px;
    border-width: 2px;
    font-size: 14px;
    border-style: solid;
    font-weight: 500;
}

button.button-verify {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 188, 0, 1.00);
    border-color: rgba(193, 226, 230, 0.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 9px;
    border-width: 2px;
    font-size: 14px;
    border-style: solid;
    font-weight: 500;
}

button.button-verify:disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 188, 0, 1.00);
    border-color: rgba(193, 226, 230, 0.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 9px;
    border-width: 2px;
    font-size: 14px;
    border-style: solid;
    font-weight: 500;
}

.regular-textbox-default:hover {
    padding: 12px 16px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-default {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-default.error {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(221, 66, 66, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-default:focus {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-default.disabled {
    padding: 12px 16px 12px 16px;
    background-color: rgba(219, 219, 219, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 80%;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-default.normal.disabled {
    background-color: #ffffff;
}

.regular-textbox-default.manualentry.disabled {
    background-color: rgba(219, 219, 219, 1.00);
}

.regular-textbox-default.disabled.error {
    border-color: rgba(221, 66, 66, 1.00);
}

.regular-textbox-style2.error {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(221, 66, 66, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-style2 {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 0px 4px 4px 0px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-style2.disabled {
    padding: 12px 16px 12px 16px;
    background-color: rgba(219, 219, 219, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 80%;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-style2:hover {
    padding: 12px 16px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textbox-style2:focus {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-default:hover {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-default:focus {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-default {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-default.disabled {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-default.error {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-readonlyterms:focus {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-readonlyterms.disabled {
    padding: 8px 16px 8px 16px;
    background-color: rgba(248, 248, 248, 1.00);
    border-color: rgba(248, 248, 248, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-readonlyterms {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-readonlyterms:hover {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textarea-readonlyterms.error {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-radio-default>.mf-comp-radiobutton>label>.title {
    margin-left: 4px;
}

.regular-radio-default>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-radio-default.disabled>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    opacity: 40%;
    border-style: solid;
    height: 18px;
}

.regular-radio-default>.mf-comp-radiobutton>label {
    overflow: hidden;
    font-family: inherit;
    color: #000000;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-radio-default {
    flex-direction: row;
}

.regular-radio-default.error>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-radio-default>.mf-comp-radiobutton {
    margin-right: 8px;
}

.regular-radio-default>.mf-comp-radiobutton>label>.check>.after-holder>.after {
    background-color: rgba(255, 183, 0, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 10px 10px 10px 10px;
    border-width: 1px;
    width: 10px;
    border-style: solid;
    height: 10px;
}

.regular-radio-vertical>.mf-comp-radiobutton>label {
    overflow: hidden;
    font-family: inherit;
    color: #000000;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-radio-vertical>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-radio-vertical>.mf-comp-radiobutton {
    margin-bottom: 8px;
}

.regular-radio-vertical>.mf-comp-radiobutton>label>.title {
    margin-left: 4px;
}

.regular-radio-vertical {
    flex-direction: column;
}

.regular-radio-vertical.error>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-radio-vertical>.mf-comp-radiobutton>label>.check>.after-holder>.after {
    background-color: rgba(255, 183, 0, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 10px 10px 10px 10px;
    border-width: 1px;
    width: 10px;
    border-style: solid;
    height: 10px;
}

.regular-radio-vertical.disabled>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    opacity: 40%;
    border-style: solid;
    height: 18px;
}

.regular-radio-style2>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-radio-style2.error>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-radio-style2>.mf-comp-radiobutton>label {
    overflow: hidden;
    font-family: inherit;
    color: #000000;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-radio-style2>.mf-comp-radiobutton {
    margin-right: 8px;
}

.regular-radio-style2>.mf-comp-radiobutton>label>.check>.after-holder>.after {
    background-color: rgba(255, 183, 0, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 10px 10px 10px 10px;
    border-width: 1px;
    width: 10px;
    border-style: solid;
    height: 10px;
}

.regular-radio-style2 {
    flex-direction: row;
}

.regular-radio-style2>.mf-comp-radiobutton>label>.title {
    margin-left: 4px;
}

.regular-radio-style2.disabled>.mf-comp-radiobutton>label>.check>.before {
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 18px;
    opacity: 40%;
    border-style: solid;
    height: 18px;
}

.regular-checkbox-default>.mf-comp-checkbox>label>.title {
    margin-left: 8px;
}

.regular-checkbox-default>.mf-comp-checkbox>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 16px;
    border-style: solid;
    height: 16px;
}

.regular-checkbox-default>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-checkbox-default>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 16px;
    fill: rgba(255, 183, 0, 1.00);
    height: 16px;
}

.regular-checkbox-default>.mf-comp-checkbox {
    margin-bottom: 12px;
}

.regular-checkbox-default.disabled>.mf-comp-checkbox>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 16px;
    border-style: solid;
    opacity: 40%;
    height: 16px;
}

.regular-checkbox-default {
    flex-direction: column;
}

.regular-checkbox-default.error>.mf-comp-checkbox>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 16px;
    border-style: solid;
    height: 16px;
}

.regular-checkbox-duplicate>.mf-comp-checkbox>label>.title {
    margin-left: 4px;
}

.regular-checkbox-duplicate>.mf-comp-checkbox>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 16px;
    border-style: solid;
    height: 16px;
}

.regular-checkbox-duplicate>.mf-comp-checkbox {
    margin-bottom: 8px;
}

.regular-checkbox-duplicate>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-checkbox-duplicate.disabled>.mf-comp-checkbox>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 16px;
    border-style: solid;
    opacity: 40%;
    height: 16px;
}

.regular-checkbox-duplicate {
    flex-direction: column;
}

.regular-checkbox-duplicate>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 16px;
    fill: rgba(255, 183, 0, 1.00);
    height: 16px;
}

.regular-checkbox-duplicate.error>.mf-comp-checkbox>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 16px;
    border-style: solid;
    height: 16px;
}

.regular-checkbox-cashaccounttypes>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.regular-checkbox-cashaccounttypes>.mf-comp-checkbox.checked>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-checkbox-cashaccounttypes {
    flex-direction: row;
}

.regular-checkbox-cashaccounttypes>.mf-comp-checkbox>label>.title {
    margin-left: 8px;
}

.regular-checkbox-cashaccounttypes>.mf-comp-checkbox>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-checkbox-cashaccounttypes>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 12px;
    fill: rgba(255, 255, 255, 1.00);
    height: 12px;
}

.regular-checkbox-cashaccounttypes.error>.mf-comp-checkbox>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-checkbox-cashaccounttypes.disabled>.mf-comp-checkbox>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-checkbox-cashaccounttypes>.mf-comp-checkbox {
    margin-right: 24px;
}

.regular-checkbox-all-cash-account.disabled>.mf-comp-checkbox>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 28px;
    border-style: solid;
    height: 28px;
}

.regular-checkbox-all-cash-account.error>.mf-comp-checkbox>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 28px;
    border-style: solid;
    height: 28px;
}

.regular-checkbox-all-cash-account>.mf-comp-checkbox>label>.title {
    margin-left: 8px;
}

.regular-checkbox-all-cash-account>.mf-comp-checkbox>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 28px;
    border-style: solid;
    height: 28px;
}

.regular-checkbox-all-cash-account {
    flex-direction: row;
}

.regular-checkbox-all-cash-account>.mf-comp-checkbox.checked>label>.check {
    background-color: rgba(51, 107, 190, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 28px;
    border-style: solid;
    height: 28px;
}

.regular-checkbox-all-cash-account>.mf-comp-checkbox {
    margin-right: 24px;
}

.regular-checkbox-all-cash-account>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 16px;
    fill: rgba(255, 255, 255, 1.00);
    height: 16px;
}

.regular-checkbox-all-cash-account>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.overlay-popup-default {
    background-color: #8888795c;
    align-items: center;
    display: flex;
    justify-content: center;
}

.overlay-popup-default>.window {
    background-color: rgba(250, 249, 249, 1.00);
    border-color: rgba(206, 206, 206, 1.00);
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
}

.overlay-popup-default>.window>.header {
    padding: 12px 12px 12px 12px;
    border-color: rgba(180, 0, 0, 1.00);
    background-color: rgba(255, 188, 0, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px 0px 1px 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
}

.overlay-popup-style1 {
    background-color: rgba(211, 211, 208, 0.58);
    align-items: center;
    display: flex;
    justify-content: center;
}

.overlay-popup-style1>.window>.header {
    padding: 10px 10px 10px 10px;
    border-color: rgba(180, 0, 0, 1.00);
    background-color: rgba(255, 179, 179, 0);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px 0px 0px 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
}

.overlay-popup-style1>.window {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(206, 206, 206, 1.00);
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
}

.regular-consent-checkbox-style1>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style1>.mf-comp-checkbox>label>.title {
    margin-left: 8px;
}

.regular-consent-checkbox-style1>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style1.disabled>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    opacity: 40%;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style1.error>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style1>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-consent-checkbox-style1>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 14px;
    fill: rgba(255, 183, 0, 1.00);
    height: 14px;
}

.regular-consent-checkbox-style1 {
    flex-direction: column;
}

.regular-consent-checkbox-style2.disabled>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style2>.mf-comp-checkbox>label>.title {
    margin-left: 12px;
}

.regular-consent-checkbox-style2>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style2.error>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(255, 255, 255, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style2>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style2>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 18px;
    fill: rgba(254, 195, 47, 1.00);
    height: 18px;
}

.regular-consent-checkbox-style2>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 18px;
    font-weight: normal;
}

.regular-consent-checkbox-style2 {
    flex-direction: column;
}

.regular-consent-checkbox-style3.disabled>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style3>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    height: 20px;
}

.regular-consent-checkbox-style3>.mf-comp-checkbox>label>.title {
    margin-left: 12px;
}

.regular-consent-checkbox-style3>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 0.00);
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style3.error>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style3>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style3 {
    flex-direction: column;
}

.regular-consent-checkbox-style3>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}

.regular-consent-checkbox-style4>.mf-comp-checkbox>label>.title {
    margin-left: 16px;
}

.regular-consent-checkbox-style4>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(54, 54, 125, 0.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style4.disabled>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(54, 54, 125, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style4>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 14px;
    fill: rgba(255, 183, 0, 1.00);
    height: 14px;
}

.regular-consent-checkbox-style4 {
    flex-direction: column;
}

.regular-consent-checkbox-style4.error>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(255, 255, 255, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style4>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style4>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 12px;
    font-weight: 600;
}

.regular-consent-checkbox-style5.disabled>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style5>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    height: 20px;
}

.regular-consent-checkbox-style5>.mf-comp-checkbox>label>.title {
    margin-left: 12px;
}

.regular-consent-checkbox-style5>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 0.00);
    /* border-color: rgba(204, 55, 55, 1.00); */
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style5>.mf-comp-checkbox.checked>label>.check {
    border-color: rgba(255, 183, 0, 1.00);
}

.regular-consent-checkbox-style5.error>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style5>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style5{
    flex-direction: column;
}

.regular-consent-checkbox-style5>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}

.regular-consent-checkbox-style6.disabled>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style6>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    height: 20px;
}

.regular-consent-checkbox-style6>.mf-comp-checkbox>label>.title {
    margin-left: 12px;
    font-weight: bold;
}

.regular-consent-checkbox-style6>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(54, 54, 125, 0.00);
    /* border-color: rgba(204, 55, 55, 1.00); */
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style6>.mf-comp-checkbox.checked>label>.check {
    border-color: rgba(255, 183, 0, 1.00);
}

.regular-consent-checkbox-style6.error>.mf-comp-checkbox>label>.check {
    min-height: 24px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.regular-consent-checkbox-style6>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style6{
    flex-direction: column;
}

.regular-consent-checkbox-style6>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}

.regular-consent-checkbox-style11>.mf-comp-checkbox {
    margin-bottom: 0px;
}

.regular-consent-checkbox-style11>.mf-comp-checkbox>label>.title {
    margin-left: 8px;
}

.regular-consent-checkbox-style11>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 50%;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style11.disabled>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    border-radius: 50%;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    opacity: 40%;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style11.error>.mf-comp-checkbox>label>.check {
    min-height: 18px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 50%;
    border-width: 1px;
    width: 18px;
    min-width: 18px;
    border-style: solid;
    height: 18px;
}

.regular-consent-checkbox-style11>.mf-comp-checkbox>label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-consent-checkbox-style11>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 14px;
    fill: rgba(255, 183, 0, 1.00);
    height: 14px;
}

.regular-consent-checkbox-style11 {
    flex-direction: column;
}

.horizondal-stepper-default>.step>.step-connector {
    border-color: rgba(18, 18, 18, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-default>.step>.step-outer-block>.step-inner-block.active {
    background-color: rgba(255, 183, 0, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    width: 60px;
    font-size: 22px;
    border-style: solid;
    font-weight: bold;
    height: 60px;
}

.horizondal-stepper-default>.step {
    width: 150px;
}

.horizondal-stepper-default>.step>.step-outer-block.active {
    padding: 8px 8px 8px 8px;
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-default>.step>.step-text.active {
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.horizondal-stepper-default>.step>.step-connector.active {
    border-color: rgba(255, 183, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-default>.step>.step-outer-block {
    padding: 8px 8px 8px 8px;
    border-color: rgba(255, 255, 255, 0.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-default>.step>.step-text {
    overflow: hidden;
    color: rgba(209, 209, 209, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.horizondal-stepper-default>.step>.step-outer-block>.step-inner-block {
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(209, 209, 209, 1.00);
    color: rgba(255, 255, 255, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    width: 60px;
    font-size: 22px;
    border-style: solid;
    font-weight: bold;
    height: 60px;
}

.horizondal-stepper-small-stepper>.step>.step-outer-block.active {
    padding: 2px 2px 2px 2px;
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-small-stepper>.step>.step-text {
    overflow: hidden;
    color: rgba(209, 209, 209, 1.00);
    text-align: center;
    font-size: 8px;
    font-weight: normal;
}

.horizondal-stepper-small-stepper>.step {
    width: 50px;
}

.horizondal-stepper-small-stepper>.step>.step-outer-block {
    padding: 2px 2px 2px 2px;
    border-color: rgba(255, 255, 255, 0.00);
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-small-stepper>.step>.step-outer-block>.step-inner-block.active {
    background-color: rgba(255, 183, 0, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 0px;
    width: 24px;
    font-size: 12px;
    border-style: solid;
    font-weight: bold;
    height: 24px;
}

.horizondal-stepper-small-stepper>.step>.step-connector {
    border-color: rgba(18, 18, 18, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-small-stepper>.step>.step-text.active {
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    font-size: 8px;
    font-weight: normal;
}

.horizondal-stepper-small-stepper>.step>.step-connector.active {
    border-color: rgba(255, 183, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-small-stepper>.step>.step-outer-block>.step-inner-block {
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(209, 209, 209, 1.00);
    color: rgba(255, 255, 255, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    width: 24px;
    font-size: 12px;
    border-style: solid;
    font-weight: bold;
    height: 24px;
}

.horizondal-stepper-medium-stepper>.step>.step-outer-block>.step-inner-block {
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(209, 209, 209, 1.00);
    color: rgba(255, 255, 255, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    width: 34px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
    height: 34px;
}

.horizondal-stepper-medium-stepper>.step>.step-outer-block>.step-inner-block.active {
    background-color: rgba(255, 183, 0, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 1px;
    width: 34px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
    height: 34px;
}

.horizondal-stepper-medium-stepper>.step>.step-connector.active {
    border-color: rgba(255, 183, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-medium-stepper>.step>.step-connector {
    border-color: rgba(18, 18, 18, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-medium-stepper>.step>.step-outer-block {
    padding: 4px 4px 4px 4px;
    border-color: rgba(255, 255, 255, 0.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-medium-stepper>.step>.step-text.active {
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: center;
    font-size: 10px;
    font-weight: normal;
}

.horizondal-stepper-medium-stepper>.step>.step-outer-block.active {
    padding: 4px 4px 4px 4px;
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-medium-stepper>.step>.step-text {
    overflow: hidden;
    color: rgba(209, 209, 209, 1.00);
    text-align: center;
    font-size: 10px;
    font-weight: normal;
}

.horizondal-stepper-medium-stepper>.step {
    width: 80px;
}

.regular-dropdown-default:hover {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default>.mf-dropdown-select-options>.item.highlight {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-dropdown-default>.mf-dropdown-select-options>.item.selected {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.regular-dropdown-default>.mf-dropdown-select-options>.item.selected:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.regular-dropdown-default>.mf-dropdown-select-options {
    background-color: rgba(255, 255, 255, 1.00);
}

.regular-dropdown-default>.mf-dropdown-select-options>.item:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-dropdown-default {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    theme-color: #ffffff;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default.error {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default:focus-within {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default.disabled {
    padding: 12px 16px 12px 16px;
    background-color: rgba(219, 219, 219, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 80%;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default_-copy>.mf-dropdown-select-options>.item.selected:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.regular-dropdown-default_-copy {
    padding: 11px 16px 11px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px 0px 0px 4px;
    theme-color: #ffffff;
    border-width: 1px 0px 1px 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default_-copy:hover {
    padding: 11px 16px 11px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px 0px 0px 4px;
    theme-color: #ffffff;
    border-width: 1px 0px 1px 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default_-copy.disabled {
    padding: 11px 16px 11px 16px;
    background-color: #e1e1e1;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px 0px 0px 4px;
    theme-color: #ffffff;
    border-width: 1px 0px 1px 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default_-copy>.mf-dropdown-select-options {
    background-color: rgba(255, 255, 255, 1.00);
    width: 200px;
}

.regular-dropdown-default_-copy>.mf-dropdown-select-options>.item:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-dropdown-default_-copy.error {
    padding: 11px 16px 11px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px 0px 0px 4px;
    theme-color: #ffffff;
    border-width: 1px 0px 1px 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default_-copy>.mf-dropdown-select-options>.item.highlight {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}

.regular-dropdown-default_-copy:focus-within {
    padding: 11px 16px 11px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px 0px 0px 4px;
    theme-color: #ffffff;
    border-width: 1px 0px 1px 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-dropdown-default_-copy>.mf-dropdown-select-options>.item.selected {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    overflow: hidden;
    color: rgba(255, 183, 0, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.regular-button-with-icon-default:active {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-default:disabled {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-default {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-default>.image {
    width: 22px;
    height: 22px;
}

.regular-button-with-icon-default:hover {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-style1>.image {
    width: 12px;
    height: 12px;
}

.regular-button-with-icon-style1:hover {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-style1:active {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-style1:disabled {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-button-with-icon-style1 {
    background-color: rgba(253, 253, 253, 1.00);
    border-color: rgba(190, 190, 190, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.horizondal-stepper-with-icon-default>.step>.step-connector.active::before {
    border-color: rgba(255, 188, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>svg {
    width: 20px;
    fill: rgba(255, 188, 0, 1.00);
    height: 20px;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block>.step-inner-block.active {
    background-color: rgba(255, 188, 0, 1.00);
    border-color: rgba(255, 188, 0, 1.00);
    color: #000000;
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 2px;
    width: 60px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
    height: 60px;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>img {
    width: 20px;
    fill: rgba(255, 188, 0, 1.00);
    height: 20px;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block>.step-inner-block.completed {
    background-color: rgba(0, 0, 0, 1.00);
    border-color: rgba(0, 0, 0, 1.00);
    color: rgba(255, 188, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 2px;
    width: 60px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
    height: 60px;
}

.horizondal-stepper-with-icon-default>.step {
    width: 160px;
}

.horizondal-stepper-with-icon-default>.step>.step-text {
    overflow: hidden;
    color: rgba(170, 170, 170, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block.active {
    padding: 8px 8px 8px 8px;
    border-color: rgba(255, 188, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-text.active {
    overflow: hidden;
    color: rgba(255, 188, 0, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block.completed {
    padding: 8px 8px 8px 8px;
    border-color: rgba(0, 0, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-connector {
    border-color: rgba(204, 204, 204, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-connector.active-reverse::after {
    border-color: rgba(255, 188, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block {
    padding: 8px 8px 8px 8px;
    border-color: rgba(255, 255, 255, 0.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-text.completed {
    overflow: hidden;
    color: rgba(0, 0, 0, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.horizondal-stepper-with-icon-default>.step>.step-connector.active {
    border-color: rgba(204, 204, 204, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-default>.step>.step-outer-block>.step-inner-block {
    background-color: rgba(204, 204, 204, 1.00);
    border-color: rgba(204, 204, 204, 1.00);
    color: rgba(0, 0, 0, 1.00);
    text-align: center;
    overflow: hidden;
    border-radius: 30px;
    border-width: 2px;
    width: 60px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    height: 60px;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-connector.active {
    border-color: rgba(204, 204, 204, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-text {
    overflow: hidden;
    font-family: inherit;
    color: rgba(170, 170, 170, 1.00);
    text-align: center;
    font-size: 8px;
    font-weight: normal;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block.completed {
    padding: 2px 2px 2px 2px;
    border-color: rgba(0, 0, 0, 1.00);
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block>.step-inner-block {
    background-color: rgba(204, 204, 204, 1.00);
    border-color: rgba(204, 204, 204, 1.00);
    color: rgba(0, 0, 0, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 2px;
    width: 24px;
    font-size: 12px;
    border-style: solid;
    font-weight: normal;
    height: 24px;
}

.horizondal-stepper-with-icon-small-stepper>.step {
    width: 50px;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-connector {
    border-color: rgba(204, 204, 204, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-connector.active-reverse::after {
    border-color: rgba(255, 188, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-connector.active::before {
    border-color: rgba(255, 188, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block>.step-inner-block.completed {
    background-color: rgba(0, 0, 0, 1.00);
    border-color: rgba(0, 0, 0, 1.00);
    color: rgba(255, 188, 0, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 2px;
    width: 24px;
    font-size: 12px;
    border-style: solid;
    font-weight: bold;
    height: 24px;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block {
    padding: 2px 2px 2px 2px;
    border-color: rgba(255, 255, 255, 0.00);
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>img {
    width: 14px;
    fill: rgba(255, 188, 0, 1.00);
    height: 14px;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block>.step-inner-block.active {
    background-color: rgba(255, 188, 0, 1.00);
    border-color: rgba(255, 188, 0, 1.00);
    color: #000000;
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 2px;
    width: 24px;
    font-size: 12px;
    border-style: solid;
    font-weight: bold;
    height: 24px;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-text.active {
    overflow: hidden;
    font-family: inherit;
    color: rgba(255, 188, 0, 1.00);
    text-align: center;
    font-size: 8px;
    font-weight: bold;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block.active {
    padding: 2px 2px 2px 2px;
    border-color: rgba(255, 188, 0, 1.00);
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-text.completed {
    overflow: hidden;
    font-family: inherit;
    color: rgba(0, 0, 0, 1.00);
    text-align: center;
    font-size: 8px;
    font-weight: bold;
}

.horizondal-stepper-with-icon-small-stepper>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>svg {
    width: 14px;
    fill: rgba(255, 188, 0, 1.00);
    height: 14px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-connector {
    border-color: rgba(204, 204, 204, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>svg {
    width: 16px;
    fill: rgba(255, 188, 0, 1.00);
    height: 16px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block>.step-inner-block.completed>.step-inner-text>img {
    width: 16px;
    fill: rgba(255, 188, 0, 1.00);
    height: 16px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-connector.active {
    border-color: rgba(204, 204, 204, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-text.completed {
    overflow: hidden;
    font-family: inherit;
    color: rgba(0, 0, 0, 1.00);
    text-align: center;
    font-size: 10px;
    font-weight: bold;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-connector.active-reverse::after {
    border-color: rgba(255, 188, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-text {
    overflow: hidden;
    font-family: inherit;
    color: rgba(170, 170, 170, 1.00);
    text-align: center;
    font-size: 10px;
    font-weight: normal;
}

.horizondal-stepper-with-icon-medium-stepper>.step {
    width: 80px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block>.step-inner-block {
    background-color: rgba(204, 204, 204, 1.00);
    border-color: rgba(204, 204, 204, 1.00);
    color: rgba(0, 0, 0, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 2px;
    width: 34px;
    font-size: 16px;
    border-style: solid;
    font-weight: normal;
    height: 34px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block {
    padding: 4px 4px 4px 4px;
    border-color: rgba(255, 255, 255, 0.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-text.active {
    overflow: hidden;
    font-family: inherit;
    color: rgba(255, 188, 0, 1.00);
    text-align: center;
    font-size: 10px;
    font-weight: bold;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-connector.active::before {
    border-color: rgba(255, 188, 0, 1.00);
    border-width: 0px 0px 2px 0px;
    border-style: solid;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block>.step-inner-block.active {
    background-color: rgba(255, 188, 0, 1.00);
    border-color: rgba(255, 188, 0, 1.00);
    color: #000000;
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 2px;
    width: 34px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
    height: 34px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block>.step-inner-block.completed {
    background-color: rgba(0, 0, 0, 1.00);
    border-color: rgba(0, 0, 0, 1.00);
    color: rgba(255, 188, 0, 1.00);
    text-align: center;
    overflow: hidden;
    font-family: inherit;
    border-radius: 30px;
    border-width: 2px;
    width: 34px;
    font-size: 16px;
    border-style: solid;
    font-weight: bold;
    height: 34px;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block.active {
    padding: 4px 4px 4px 4px;
    border-color: rgba(255, 188, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.horizondal-stepper-with-icon-medium-stepper>.step>.step-outer-block.completed {
    padding: 4px 4px 4px 4px;
    border-color: rgba(0, 0, 0, 1.00);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}

.countdown-timer-default>.splitter {
    background-color: #ffffff;
    border-color: #ffffff;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-family: inherit;
    overflow: hidden;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    hgap: 0px;
}

.countdown-timer-default>.clock {
    background-color: #ffffff;
    border-color: #ffffff;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-family: inherit;
    overflow: hidden;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    hgap: 0px;
}

.custom-component-checkbox-default>.mf-comp-checkbox>label>.check {
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-component-checkbox-default>.mf-comp-checkbox {
    margin-right: 0px;
    padding: 20px 20px 20px 20px;
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(177, 177, 177, 1.00);
    border-radius: 12px;
    border-width: 1px;
    width: 48%;
    border-style: solid;
}

.custom-component-checkbox-default.disabled>.mf-comp-checkbox>label>.check {
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    opacity: 40%;
    border-style: solid;
    height: 24px;
}

.custom-component-checkbox-default>.mf-comp-checkbox>label>.check>.after-holder>img {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    direction: column;
    height: 20px;
}

.custom-component-checkbox-default {
    flex-direction: row;
}

.custom-component-checkbox-default>.mf-comp-checkbox>label>.check>.after-holder>svg {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    direction: column;
    height: 20px;
}

.custom-component-checkbox-default.error>.mf-comp-checkbox>label>.check {
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-component-checkbox-default>.mf-comp-checkbox>label>.component-holder {
    margin-left: 12px;
}

.custom-component-checkbox-default>.mf-comp-checkbox.checked>label>.check {
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.image-button-default {
    border-color: #ffffff;
    border-width: 0px;
    border-style: solid;
}

.hyperlink-default {
    font-family: inherit;
    overflow: hidden;
    color: rgba(3, 108, 252, 1.00);
    text-align: left;
    font-size: 14px;
    text-decoration: underline;
    text-overflow: clip;
    font-weight: normal;
}

.custom-consent-checkbox-default>.mf-comp-checkbox.checked>.label>.check {
    background-color: #ffffff;
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-default>.mf-comp-checkbox {
    margin-bottom: 20px;
}

.custom-consent-checkbox-default>.mf-comp-checkbox>.label>.check>.after-holder>svg {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    height: 20px;
}

.custom-consent-checkbox-default>.mf-comp-checkbox>.label>.title {
    margin-left: 20px;
}

.custom-consent-checkbox-default>.mf-comp-checkbox>.label>.check {
    min-height: 24px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-default.error>.mf-comp-checkbox>.label>.check {
    min-height: 24px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-default.disabled>.mf-comp-checkbox>.label>.check {
    min-height: 24px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 20px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    opacity: 40%;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-duplicate>.mf-comp-checkbox.checked>.label>.check {
    background-color: #ffffff;
    border-color: rgba(255, 183, 0, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-duplicate>.mf-comp-checkbox>.label>.title {
    margin-left: 20px;
}

.custom-consent-checkbox-duplicate>.mf-comp-checkbox>.label>.check {
    min-height: 24px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-duplicate.disabled>.mf-comp-checkbox>.label>.check {
    min-height: 24px;
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    opacity: 40%;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-duplicate.error>.mf-comp-checkbox>.label>.check {
    min-height: 24px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    border-radius: 4px;
    border-width: 1px;
    width: 24px;
    min-width: 24px;
    border-style: solid;
    height: 24px;
}

.custom-consent-checkbox-duplicate>.mf-comp-checkbox {
    margin-bottom: 20px;
}

.custom-consent-checkbox-duplicate>.mf-comp-checkbox>.label>.check>.after-holder>svg {
    width: 20px;
    fill: rgba(255, 183, 0, 1.00);
    height: 20px;
}

.formatted-input-box-default:hover {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.formatted-input-box-default:focus {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.formatted-input-box-default {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.formatted-input-box-default.disabled {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.formatted-input-box-default.error {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(204, 55, 55, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.custom-component-tooltip-default>.tooltip-icon {
    background-color: rgba(204, 204, 204, 0);
    border-color: #ffffff;
    color: #000000;
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 15px;
    border-width: 0px;
    width: 30px;
    font-size: 1.0em;
    border-style: solid;
    font-weight: normal;
    height: 30px;
}

.custom-component-tooltip-default>.popup-tooltip {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgba(255, 223, 153, 1.00);
    border-color: rgba(255, 195, 28, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 8px;
    border-width: 2px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.custom-component-tooltip-default>.popup-tooltip>.popup-tooltip-header>.popup-tooltip-close {
    width: 20px;
    height: 20px;
}

.custom-component-tooltip-default>.tooltip-icon>.icon {
    width: 20px;
    fill: rgba(0, 0, 0, 1.00);
    height: 20px;
}

.custom-component-tooltip-default>.mf-tooltip-popup-arrow {
    border-color: rgba(255, 195, 28, 1.00);
    border-width: 2px;
    border-style: solid;
}

.simple-listview-default {
    background-color: #ffffff;
    border-color: rgba(182, 182, 182, 1.00);
    border-width: 0px;
    flex-direction: column;
    border-style: solid;
}

.simple-listview-default>.mf-simple-listview-item {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(182, 182, 182, 1.00);
    border-width: 0px 0px 0px 0px;
    border-style: solid;
}

.circular-progress-bar-default>.determinate-inner-circle>.determinate-indication {
    stroke: rgba(255, 188, 0, 1.00);
}

.circular-progress-bar-default>.determinate-inner-circle>.determinate-track {
    stroke: rgba(255, 255, 255, 1.00);
}

.circular-progress-bar-default {
    color: rgba(255, 188, 0, 1.00);
}

.circular-progress-bar-default>.progress-value {
    font-family: inherit;
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.regular-textboxwith-action-default>.with-action>.result>.mf-result-icon {
    width: 10px;
    height: 10px;
}

.regular-textboxwith-action-default>.with-action>button {
    padding: 4px 8px 4px 8px;
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: lighter;
}

.regular-textboxwith-action-default {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textboxwith-action-default>.with-action>button:disabled {
    padding: 4px 8px 4px 8px;
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: lighter;
}

.regular-textboxwith-action-default>.with-action>.result {
    font-family: inherit;
    overflow: hidden;
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.regular-textboxwith-action-default.error {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(221, 66, 66, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.regular-textboxwith-action-default>.with-action>button:hover {
    padding: 4px 8px 4px 8px;
    background-color: rgba(18, 18, 18, 1.00);
    border-color: rgba(18, 18, 18, 1.00);
    color: rgba(254, 195, 47, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: lighter;
}

.regular-textboxwith-action-default.disabled {
    padding: 12px 16px 12px 16px;
    background-color: #ffffff;
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

.regular-textboxwith-action-default:focus-within {
    border-color: rgba(154, 155, 158, 1.00);
}

.regular-textboxwith-action-default>.with-action {
    top: calc(50% - 16px);
    right: 16px;
}

.simple-toast-success>.toast {
    padding: 8px 16px 8px 16px;
    background-color: rgba(80, 95, 22, 1.00);
    border-color: rgba(182, 182, 182, 1.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 0px;
    vgap: 8px;
    width: 450px;
    font-size: 14px;
    margin-bottom: 8px;
    border-style: solid;
    font-weight: 700;
    height: 50px;
}

.simple-toast-success>.toast>.content {
    width: calc(100% - 24px - 16px);
}

.simple-toast-success>.toast>.close-btn {
    width: 24px;
    height: 24px;
}

.simple-toast-success>.toast>.highlight {
    background-color: rgba(245, 245, 245, 0.98);
    height: 4px;
}

.simple-toast-failure>.toast>.close-btn {
    width: 24px;
    height: 24px;
}

.simple-toast-failure>.toast>.highlight {
    background-color: rgba(245, 245, 245, 0.98);
    height: 4px;
}

.simple-toast-failure>.toast {
    padding: 8px 16px 8px 16px;
    background-color: rgba(204, 50, 50, 1.00);
    border-color: rgba(182, 182, 182, 1.00);
    color: rgba(245, 245, 245, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 0px;
    vgap: 8px;
    width: 450px;
    font-size: 14px;
    margin-bottom: 8px;
    border-style: solid;
    font-weight: 700;
    height: 50px;
}

.simple-toast-failure>.toast>.content {
    width: calc(100% - 24px - 16px);
}

.dropdown-with-input-search-default>.popup>.row-drop-item.highlighted {
    padding: 8px 16px 8px 16px;
    background-color: rgba(221, 221, 221, 1.00);
    border-color: #ffffff;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default>.popup>.row-drop-item {
    padding: 8px 16px 8px 16px;
    background-color: #ffffff;
    border-color: rgba(255, 255, 255, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default:hover {
    padding: 12px 16px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default.error {
    padding: 12px 16px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(221, 66, 66, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default:focus-within {
    padding: 12px 16px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default.disabled {
    padding: 12px 16px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    opacity: 40%;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default>.popup {
    background-color: rgba(245, 245, 245, 1.00);
    border-color: rgba(182, 182, 182, 1.00);
    border-radius: 4px;
    theme-color: rgba(98, 0, 238, 1.00);
    border-width: 1px;
    border-style: solid;
}

.dropdown-with-input-search-default {
    padding: 12px 40px 12px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    border-color: rgba(154, 155, 158, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default>.popup>.row-drop-item.selected {
    padding: 8px 16px 8px 16px;
    background-color: rgba(189, 189, 189, 1.00);
    border-color: rgba(98, 0, 238, 1.00);
    color: rgba(98, 0, 238, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
}

.dropdown-with-input-search-default>.popup>.row-drop-item:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(221, 221, 221, 1.00);
    border-color: #ffffff;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.dropdown-with-input-search-default>.popup>.row-drop-item>.data>.highlighted {
    color: rgba(98, 0, 238, 1.00);
}

.dropdown-with-input-search-default>.popup>.cancel-icon {
    width: 16px;
    height: 16px;
}

.dropdown-with-input-search-default>.popup>.row-drop-item.selected:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(189, 189, 189, 1.00);
    border-color: rgba(98, 0, 238, 1.00);
    color: rgba(98, 0, 238, 1.00);
    text-align: left;
    overflow: hidden;
    font-family: inherit;
    border-width: 0px;
    font-size: 14px;
    border-style: solid;
    font-weight: bold;
}

.custom-datepicker-default {
    padding: 8px 16px 8px 16px;
    border-color: rgba(182, 182, 182, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 4px;
    border-width: 1px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
    width: 100%;
}

.custom-datepicker-default.error {
    border: 1px solid rgba(221, 66, 66, 1.00);
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    font-family: inherit;
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.custom-datepicker-default>.mf-custom-datepicker-inputfieldbox>.mf-custom-datepicker-input {
    color: rgba(18, 18, 18, 1.00);
    font-size: 14px;
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem {
    padding: 8px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 1.00);
    font-family: inherit;
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.monthdays.selected {
    background-color: #ffbc00;
    font-family: inherit;
    overflow: hidden;
    color: rgba(255, 255, 255, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem.selected {
    padding: 8px 16px 8px 16px;
    background-color: #ffbc00;
    font-family: inherit;
    overflow: hidden;
    color: rgba(255, 255, 255, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days {
    background-color: rgba(255, 255, 255, 1.00);
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-year>.mf-custom-calendar-yearitem:hover {
    padding: 8px 16px 8px 16px;
    background-color: rgba(245, 245, 245, 1.00);
    font-family: inherit;
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.custom-datepicker-default>.mf-custom-calendar {
    padding: 8px 16px 8px 16px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    background-color: rgba(255, 255, 255, 1.00);
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.monthdays {
    background-color: rgba(255, 255, 255, 1.00);
    font-family: inherit;
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.custom-datepicker-default>.mf-custom-calendar>.mf-custom-calendar-daysholder>.mf-custom-calendar-days>.monthdays:hover {
    background-color: rgba(245, 245, 245, 1.00);
    font-family: inherit;
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.label-popup-label {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.label-popup-default {
    overflow: hidden;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 12px;
    font-weight: normal;
}

.label-description14px-italic {
    overflow: hidden;
    font-family: inherit;
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-size: 14px;
    text-overflow: clip;
    font-weight: 500;
    font-style: italic;
}

.custom-component-tooltip-default>.mf-tooltip-icon {
    background-color: rgba(204, 204, 204, 0);
    border-color: #ffffff;
    color: #000000;
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 15px;
    border-width: 0px;
    width: 30px;
    font-size: 1.0em;
    border-style: solid;
    font-weight: normal;
    height: 30px;
}

.custom-component-tooltip-default>.mf-tooltip-popup {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgba(255, 223, 153, 1.00);
    border-color: rgba(255, 195, 28, 1.00);
    color: rgba(18, 18, 18, 1.00);
    text-align: left;
    font-family: inherit;
    overflow: hidden;
    border-radius: 8px;
    border-width: 2px;
    font-size: 14px;
    border-style: solid;
    font-weight: normal;
}

.custom-component-tooltip-default>.mf-tooltip-popup>.mf-tooltip-popup-header>.mf-tooltip-popup-close {
    width: 20px;
    height: 20px;
}

.custom-component-tooltip-default>.mf-tooltip-icon>.icon {
    width: 20px;
    fill: rgba(0, 0, 0, 1.00);
    height: 20px;
}

.custom-component-tooltip-default>.mf-popup-tooltip-arrow {
    border-color: rgba(255, 195, 28, 1.00);
    border-width: 2px;
    border-style: solid;
}