
.tax-residency-notsingaporev2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.tax-residency-notsingaporev2>.row1 {
    padding: 16px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row1>.label5 {
    width: 160px;
}

.tax-residency-notsingaporev2>.row1>.row7 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row1>.row7>.image-button8 {
    width: 16px;
    height: 16px;
}

.tax-residency-notsingaporev2>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row3>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row3>.dd-istaxidentification-number-available {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row3>.tb-tin {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row3>.tb-reason {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row8 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row3>.dd-country-first {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row8>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row3>.tb-tin-first {
    margin: 0px 0px 4px 0px;
    width: 100%;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.tax-residency-notsingaporev2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row1 {
    padding: 16px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row1>.label5 {
    width: 160px;
}

.tax-residency-notsingaporev2>.row1>.row7 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row3>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row3>.dd-istaxidentification-number-available {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row3>.tb-tin {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row3>.tb-reason {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row8 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row3>.dd-country-first {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row8>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row3>.tb-tin-first {
    margin: 0px 0px 4px 0px;
    width: 100%;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.tax-residency-notsingaporev2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row1 {
    padding: 16px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row1>.label5 {
    width: 160px;
}

.tax-residency-notsingaporev2>.row1>.row7 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row2>.row3>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row3>.row3>.dd-istaxidentification-number-available {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row4>.row3>.tb-tin {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row6>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row6>.row5>.row3>.tb-reason {
    margin: 0px 0px 4px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row8 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row2>.row3>.dd-country-first {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.tax-residency-notsingaporev2>.row8>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.tax-residency-notsingaporev2>.row8>.row4>.row3>.tb-tin-first {
    margin: 0px 0px 4px 0px;
    width: 100%;
}
}

