
.c-a-r-page {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.c-a-r-page>.row3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.c-a-r-page>.row3>.row4 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-page>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.c-a-r-page>.row3>.row8 {
    padding: 20px 20px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.c-a-r-page {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.c-a-r-page>.row3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.c-a-r-page>.row3>.row4 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-page>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.c-a-r-page>.row3>.row8 {
    padding: 20px 20px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.c-a-r-page {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.c-a-r-page>.row3 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.c-a-r-page>.row3>.row4 {
    background-color: rgba(221, 222, 223, 0);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-a-r-page>.row3>.row4>.u-i-component6 {
    width: 100%;
}

.c-a-r-page>.row3>.row8 {
    padding: 20px 20px 12px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}
}

