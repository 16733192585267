.pep-tooltip {
    padding: 4px 24px 24px 24px;
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 500px;
    max-height: 400px;
    border-style: solid;
    overflow-y: auto;
}

.pep-tooltip>.row2 {
    padding: 8px 0px 0px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .pep-tooltip {
        padding: 4px 24px 24px 24px;
        background-color: rgba(255, 255, 255, 0);
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        overflow-y: auto;
        max-height: 400px;
        border-width: 0px;
        width: 320px;
        border-style: solid;
    }
    .pep-tooltip>.row2 {
        padding: 8px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .pep-tooltip {
        padding: 4px 24px 24px 24px;
        background-color: rgba(255, 255, 255, 0);
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        overflow-y: auto;
        border-width: 0px;
        width: 320px;
        border-style: solid;
    }
    .pep-tooltip>.row2 {
        padding: 8px 0px 0px 0px;
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
}