/* ================================================ */
/* Checkboxes */

.mf-custom-consent-checkboxes {
    display: flex;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox {
    position: relative;
    width: 100%;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    overflow: visible !important;
    width: 100%;
}


.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.title {
    width: 100%;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.check {
    position: relative;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.check>.after-holder {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.check>.after-holder>.after {
    background-color: transparent;
    width: 80%;
    height: 30%;
    border-width: 0px 0px 3px 3px;
    border-style: solid;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.check>.after-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(0);
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox.checked>.label>.check>.after-holder {
    opacity: 1;
    transform: scale(1) translate(8%, -6%);
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.check>.before {
    position: relative;
}

.mf-custom-consent-checkboxes>.mf-comp-checkbox>.label>.check>.before>.mf-ripple-block {
    border-radius: 50%;
    z-index: 3;
}

/* ========================================================= */
/* sample */

.mf-custom-consent-checkboxes.sample {
    flex-direction: column;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox {
    margin-bottom: 8px;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox>.label>.title {
    margin-left: 8px;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox>.label {
    color: rgb(18, 18, 18);
    font-size: 16px;
    text-decoration: unset;
    font-style: normal;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox>.label>.check {
    background-color: #ffffff;
    border-color: #a7a7a7;
    border-radius: 3px;
    border-width: 1px;
    width: 20px;
    border-style: solid;
    height: 20px;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox>.label>.check>.after-holder>svg {
    width: 14px;
    fill: #6200ee;
    height: 14px;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox>.label>.check>.before>.mf-ripple-block {
    width: 40px;
    height: 40px;
    left: -12px;
    top: -12px;
}

.mf-custom-consent-checkboxes.sample>.mf-comp-checkbox>.label>.check>.before>.mf-ripple-block>.ripple {
    background-color: rgba(75, 75, 214, 0.3);
}