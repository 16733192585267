.mf-simple-listview {
    overflow-y: visible;
    display: flex;
    flex-wrap: wrap;
}

.mf-simple-listview>.mf-simple-listview-item {
    width: 100%;
    /* overflow: hidden; */
}

/* to keep the last child 0px always */
.mf-simple-listview>.mf-simple-listview-item:last-child {
    border-bottom: 0px !important;
}


/* ============================= */

.mf-simple-listview.sample {

    background: #f7f7f7;
    padding: 0px;
    border: 1px solid rgb(86, 86, 86);
}

.mf-simple-listview.sample>.mf-simple-listview-item {
    border-bottom: 1px solid rgb(86, 86, 86);
    padding: 8px;
}