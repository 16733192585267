.due-digilence {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.due-digilence>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row1>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row1>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row1>.cell1>.dd-employement-status {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row1>.cell1>.dd-occupation {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row2 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row2>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row2>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row2>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row2>.cell1>.dd-industry {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row2>.cell1>.dd-sub-industry {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row3 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row4 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row4>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row4>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row4>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row4>.cell1>.tb-address-line1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row5 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row5>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row5>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row5>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row5>.cell1>.tb-address-line2 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row6 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row6>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row6>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row6>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row6>.cell1>.dd-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row6>.cell1>.tb-state {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row7 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row7>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row7>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row7>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row7>.cell1>.tb-postal-code {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row8 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row8>.rownew {
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row8>.rownew>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row8>.rownew>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row8>.rownew>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row8>.rownew>.cell1>.dd-estimated-net-worth {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row8>.rownew>.cell1>.row6 {
    padding: 4px 4px 4px 4px;
    border-color: rgba(154, 155, 158, 1.00);
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-radius: 6px;
    border-width: 1px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row8>.rownew>.cell1>.row6>.u-i-component12 {
    width: 100%;
    height: 100%;
}

.due-digilence>.row8>.cell1 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row8>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row8>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row8>.cell1>.simple-listview11 {
    margin: 4px 0px 0px 0px;
    width: 100%;
}

.due-digilence>.row8>.cell1>.row-add-country {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row4 {
    border-color: #000000;
    margin: 0px 0px 12px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row4>.row9 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row4>.row9>.row10 {
    border-color: #000000;
    margin: 0px 0px 0px 12px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row4>.row9>.row10>.radio-accredited-investor {
    display: flex;
}

.due-digilence>.row19>.row3>.row6 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row6>.label18 {
    margin: 1px 0px 0px 0px;
}

.due-digilence>.row19>.row3>.row6>.row10 {
    border-color: #000000;
    align-items: center;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 50%;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row6>.row10>.row11 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row19>.row3>.row6>.row10>.row11>.label19 {
    margin: 8px 0px 0px 0px;
}

.due-digilence>.row19>.row3>.row6>.row10>.row11>.label20 {
    margin: 8px 0px 0px 0px;
}

.due-digilence>.row19>.row3>.row6>.row10>.row12 {
    border-color: #000000;
    margin: 12px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row9 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-end;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row9>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row9>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row9>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row9>.cell1>.dd-howdidiyouknowabut-m-s-s-g {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row9>.cell1>.tb-tagyouaccounttospecifiec {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row10 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-end;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row10>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row10>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row10>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row10>.cell1>.dd-relatedtoanyemployee {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row10>.cell2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row10>.cell2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row10>.cell2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row10>.cell2>.tb-nameofpersonalrelation {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row11 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row11>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row11>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row11>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row11>.cell1>.dd-investment-objectuve {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row11>.cell1>.dd-risktolerence {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row12 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row12>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row12>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row12>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row12>.cell1>.dd-percentageofliquidassets {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row13 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row13>.cell2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row13>.cell2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row13>.cell2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row13>.cell2>.dd-highest-educationlevel {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row13>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row13>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row13>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row13>.cell1>.dd-fieldof-study {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row14 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row15 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row15>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row15>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row15>.row3>.ddpepquestion1 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row16 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row16>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row16>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row16>.row3>.ddpepquestion2 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row17 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row17>.row2 {
    border-color: #000000;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row17>.row3 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row17>.row3>.ddpepquestion3 {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row18 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row18>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row18>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row18>.row1>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row18>.row1>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row18>.row1>.cell1>.tb-nameof-politically-exposed-individual {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row18>.row1>.cell1>.tb-relationshiptotheapplicant {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row18>.row1>.cell1>.tb-postion-titleof-p-e-p {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row18>.row1>.cell1>.tb-p-e-p-employer {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row18>.row1>.cell1>.dd-p-e-p-country {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.buttons {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.buttons>.btn-back {
    width: 90px;
}

.due-digilence>.buttons>.btn-next {
    margin: 0px 0px 0px 32px;
    width: 90px;
}

.due-digilence>.overlay-popup7 {
    originaldisplayvalue: flex;
}

.due-digilence>.row20 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-end;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row20>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row20>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row20>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row20>.cell1>.dd-shareholder {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row20>.cell2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row20>.cell2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row20>.cell2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row20>.cell2>.tb-companyname {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row21 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-end;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row21>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row21>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row21>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row21>.cell1>.dd-bankrupt {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row21>.cell2 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row21>.cell2>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row21>.cell2>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row21>.cell2>.dd-dischargedbankrupt {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row22 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-end;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row22>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row22>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row22>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row22>.cell1>.dd-staffofmaybank {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.due-digilence>.row23 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-end;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row23>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 49%;
    border-style: solid;
}

.due-digilence>.row23>.cell1>.title {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.due-digilence>.row23>.cell1>.title>.important {
    margin: 0px 0px 0px 4px;
}

.due-digilence>.row23>.cell1>.tb-promocode {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .due-digilence {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }

    .due-digilence>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row1>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row1>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row1>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row1>.cell1>.dd-employement-status {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row1>.cell1>.dd-occupation {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row2>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row2>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row2>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row2>.cell1>.dd-industry {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row2>.cell1>.dd-sub-industry {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row4>.cell1>.tb-address-line1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row5 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row5>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row5>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row5>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row5>.cell1>.tb-address-line2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row6 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row6>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row6>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row6>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row6>.cell1>.dd-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row6>.cell1>.tb-state {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row7 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row7>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row7>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row7>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row7>.cell1>.tb-postal-code {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row8 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row8>.rownew>.cell1>.dd-estimated-net-worth {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row8>.rownew>.cell1>.row6 {
        padding: 4px 4px 4px 4px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-radius: 6px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1>.row6>.u-i-component12 {
        width: 100%;
        height: 100%;
    }

    .due-digilence>.row8>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row8>.cell1>.simple-listview11 {
        width: 100%;
    }

    .due-digilence>.row8>.cell1>.row-add-country {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }

    .due-digilence>.row19 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4 {
        border-color: #000000;
        margin: 0px 0px 12px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4>.row9 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4>.row9>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4>.row9>.row10>.radio-accredited-investor {
        display: flex;
    }

    .due-digilence>.row19>.row3>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row6>.label18 {
        margin: 12px 0px 0px 0px;
    }

    .due-digilence>.row19>.row3>.row6>.row10 {
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row11 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row11>.label19 {
        margin: 12px 0px 0px 0px;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row11>.label20 {
        margin: 12px 0px 0px 0px;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row12 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row9>.cell1>.dd-howdidiyouknowabut-m-s-s-g {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row9>.cell1>.tb-tagyouaccounttospecifiec {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row10 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row10>.cell1>.dd-relatedtoanyemployee {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row10>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row10>.cell2>.tb-nameofpersonalrelation {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row11 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row11>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row11>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row11>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row11>.cell1>.dd-investment-objectuve {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row11>.cell1>.dd-risktolerence {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row12 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row12>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row12>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row12>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row12>.cell1>.dd-percentageofliquidassets {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row13 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row13>.cell2>.dd-highest-educationlevel {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row13>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row13>.cell1>.dd-fieldof-study {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row14 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15>.row3>.ddpepquestion1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row16 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row16>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row16>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row16>.row3>.ddpepquestion2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row17 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row17>.row2 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row17>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row17>.row3>.ddpepquestion3 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-nameof-politically-exposed-individual {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-relationshiptotheapplicant {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-postion-titleof-p-e-p {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-p-e-p-employer {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.dd-p-e-p-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.buttons {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.buttons>.btn-back {
        width: 90px;
    }

    .due-digilence>.buttons>.btn-next {
        margin: 0px 0px 0px 32px;
        width: 90px;
    }

    .due-digilence>.overlay-popup7 {
        originaldisplayvalue: flex;
    }

    .due-digilence>.row20 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row20>.cell1>.dd-shareholder {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row20>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row20>.cell2>.tb-companyname {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row21 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row21>.cell1>.dd-bankrupt {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row21>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row21>.cell2>.dd-dischargedbankrupt {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row22 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row22>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row22>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row22>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row22>.cell1>.dd-staffofmaybank {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row23 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row23>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row23>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row23>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row23>.cell1>.tb-promocode {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .due-digilence {
        background-color: #ffffff;
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }

    .due-digilence>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row1>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row1>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row1>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row1>.cell1>.dd-employement-status {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row1>.cell1>.dd-occupation {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row2 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row2>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row2>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row2>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row2>.cell1>.dd-industry {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row2>.cell1>.dd-sub-industry {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row3 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row4>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row4>.cell1>.tb-address-line1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row5 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row5>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row5>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row5>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row5>.cell1>.tb-address-line2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row6 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row6>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row6>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row6>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row6>.cell1>.dd-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row6>.cell1>.tb-state {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row7 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row7>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row7>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row7>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row7>.cell1>.tb-postal-code {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row8 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row8>.rownew>.cell1>.dd-estimated-net-worth {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row8>.rownew>.cell1>.row6 {
        padding: 4px 4px 4px 4px;
        border-color: rgba(154, 155, 158, 1.00);
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-radius: 6px;
        border-width: 1px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.rownew>.cell1>.row6>.u-i-component12 {
        width: 100%;
        height: 100%;
    }

    .due-digilence>.row8>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row8>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row8>.cell1>.simple-listview11 {
        width: 100%;
    }

    .due-digilence>.row8>.cell1>.row-add-country {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }

    .due-digilence>.row19 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4 {
        border-color: #000000;
        margin: 0px 0px 12px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4>.row9 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4>.row9>.row10 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row4>.row9>.row10>.radio-accredited-investor {
        display: flex;
    }

    .due-digilence>.row19>.row3>.row6 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row6>.label18 {
        margin: 12px 0px 0px 0px;
    }

    .due-digilence>.row19>.row3>.row6>.row10 {
        border-color: #000000;
        align-items: center;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row11 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row11>.label19 {
        margin: 12px 0px 0px 0px;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row11>.label20 {
        margin: 12px 0px 0px 0px;
    }

    .due-digilence>.row19>.row3>.row6>.row10>.row12 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row9>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row9>.cell1>.dd-howdidiyouknowabut-m-s-s-g {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row9>.cell1>.tb-tagyouaccounttospecifiec {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row10 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row10>.cell1>.dd-relatedtoanyemployee {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row10>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row10>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row10>.cell2>.tb-nameofpersonalrelation {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row11 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row11>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row11>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row11>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row11>.cell1>.dd-investment-objectuve {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row11>.cell1>.dd-risktolerence {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row12 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row12>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row12>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row12>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row12>.cell1>.dd-percentageofliquidassets {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row13 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row13>.cell2>.dd-highest-educationlevel {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row13>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row13>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row13>.cell1>.dd-fieldof-study {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row14 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row15>.row3>.ddpepquestion1 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row16 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row16>.row2 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row16>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row16>.row3>.ddpepquestion2 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row17 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row17>.row2 {
        border-color: #000000;
        align-items: center;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row17>.row3 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row17>.row3>.ddpepquestion3 {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row18>.row1>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-nameof-politically-exposed-individual {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-relationshiptotheapplicant {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-postion-titleof-p-e-p {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.tb-p-e-p-employer {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row18>.row1>.cell1>.dd-p-e-p-country {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.buttons {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.buttons>.btn-back {
        width: 90px;
    }

    .due-digilence>.buttons>.btn-next {
        margin: 0px 0px 0px 32px;
        width: 90px;
    }

    .due-digilence>.overlay-popup7 {
        originaldisplayvalue: flex;
    }

    .due-digilence>.row20 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row20>.cell1>.dd-shareholder {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row20>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row20>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row20>.cell2>.tb-companyname {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row21 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row21>.cell1>.dd-bankrupt {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row21>.cell2 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell2>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row21>.cell2>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row21>.cell2>.dd-dischargedbankrupt {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row22 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row22>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row22>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row22>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row22>.cell1>.dd-staffofmaybank {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }

    .due-digilence>.row23 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row23>.cell1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row23>.cell1>.title {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }

    .due-digilence>.row23>.cell1>.title>.important {
        margin: 0px 0px 0px 4px;
    }

    .due-digilence>.row23>.cell1>.tb-promocode {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
}