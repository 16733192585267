.c-f-d-percentageof-ownership {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 100%;
}

.c-f-d-percentageof-ownership>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-d-percentageof-ownership>.row1 {
    border-color: #000000;
    margin: 16px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: space-between;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-d-percentageof-ownership>.row1>.cell1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-account-number {
    margin: 8px 0px 2px 0px;
    width: 100%;
    margin-right: 4px;
}

.c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-percentageof-ownership {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-f-d-percentageof-ownership>.row1>.cell1>.input-section {
    display: flex;
    align-items: center;
    width: 100%;
}

.c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-percentageof-ownership {
    margin: 8px 0px 2px 0px;
    width: 100%;
}

.c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.image-button8 {
    width: 18px;
    height: 18px;
    margin-left: 12px;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .c-f-d-percentageof-ownership {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .c-f-d-percentageof-ownership>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-d-percentageof-ownership>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-account-number {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-percentageof-ownership {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.image-button8 {
        width: 18px;
        height: 18px;
        margin-left: 12px;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .c-f-d-percentageof-ownership {
        background-color: #ffffff;
        border-color: #ffffff;
        display: block;
        originaldisplayvalue: block;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 100%;
    }
    .c-f-d-percentageof-ownership>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-d-percentageof-ownership>.row1 {
        border-color: #000000;
        margin: 16px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: space-between;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1 {
        border-color: #000000;
        margin: 12px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-account-number {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.tb-percentageof-ownership {
        margin: 8px 0px 2px 0px;
        width: 100%;
    }
    .c-f-d-percentageof-ownership>.row1>.cell1>.input-section>.image-button8 {
        width: 18px;
        height: 18px;
        margin-left: 12px;
    }
}