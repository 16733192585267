.mf-dropdown {
    position: relative;
    overflow: visible !important;
    cursor: pointer;
}

.mf-dropdown>.base {
    position: relative;
    overflow: visible !important;
    background-color: inherit;
    width: 100%;
}

.mf-dropdown>.base>.label {
    position: absolute;
    top: calc(50% - 10px);
    left: 16px;
    color: #a7a7a7;
    font-size: inherit;
    background-color: inherit;
    user-select: none;
    transform-origin: 0 0;
    transition: all 0.3s;
}

.mf-dropdown>.base>.label.up {
    top: -10px;
    left: 8px;
    padding: 0px 8px;
    transform: scale(0.8);
    transition: all 0.3s;
}

.mf-dropdown>.base>input {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-align: inherit;
    text-decoration: inherit;
    text-overflow: inherit;
    overflow: inherit;
    white-space: inherit;
    cursor: pointer;
}

.mf-dropdown>.caret {
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #888;
    pointer-events: none;
}

.mf-dropdown>.mf-dropdown-select-options {
    outline: none;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    transition: all 0.3s;
    position: absolute;
    z-index: 5;
    top: 10px;
    left: 0px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
}

.mf-dropdown>.mf-dropdown-select-options>.item {
    padding: 8px 16px;
    cursor: pointer;
    transition: all .25s ease;
}

.mf-dropdown>.mf-dropdown-select-options>.item:hover {
    cursor: pointer;
}

.mf-dropdown>.mf-dropdown-select-options>.item.selected,
.mf-dropdown>.mf-dropdown-select-options>.item.selected:hover {}


/* ========================================================== */

.mf-dropdown.sample {
    width: 240px;
    padding: 8px 16px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(218, 218, 218);
    white-space: normal;
    color: rgb(34, 34, 34);
    text-align: left;
    text-decoration: unset;
    font-style: normal;
    overflow: hidden;
    border-radius: 2px;
    border-width: 1px;
    font-size: 16px;
    text-overflow: unset;
    border-style: solid;
    font-weight: normal;
}

.mf-dropdown.sample>.mf-dropdown-select-options {
    background-color: #ffffff;
}

.mf-dropdown.sample>.mf-dropdown-select-options>.item:hover,
.mf-dropdown.sample>.mf-dropdown-select-options>.item.highlight {
    background-color: #f5f5f5;
}

.mf-dropdown.sample>.mf-dropdown-select-options>.item.selected,
.mf-dropdown.sample>.mf-dropdown-select-options>.item.selected:hover {
    background-color: #ebebeb;
    color: #000;
}