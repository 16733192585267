
.acknowedge-termsv2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.acknowedge-termsv2>.acknowledge-selectall {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.acknowledge-selectall>.sa1 {
    margin: 16px 0px 0px 8px;
}

.acknowedge-termsv2>.acknowledge-title {
    padding: 20px 0px 16px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.acknowedge-termsv2>.row1 {
    padding: 0px 0px 0px 12px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.row2 {
    padding: 12px 0px 12px 26px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.pdpatitle {
    padding: 20px 0px 4px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.acknowedge-termsv2>.descpdpa {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.descpdpa>.pdpa1 {
    margin: 8px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.pdpa2 {
    margin: 16px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.row19 {
    border-color: #000000;
    margin: 8px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.acknowedge-termsv2>.descpdpa>.row19>.rcc1 {
    margin: 0px 16px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.row19>.rcc2 {
    margin: 0px 16px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.label16 {
    margin: 16px 0px 0px 0px;
    display: none;
}

.acknowedge-termsv2>.descpdpa>.label17 {
    margin: 24px 0px 0px 0px;
    display: none;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.acknowedge-termsv2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.acknowedge-termsv2>.acknowledge-selectall {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.acknowledge-selectall>.sa1 {
    margin: 16px 0px 0px 8px;
}

.acknowedge-termsv2>.acknowledge-title {
    padding: 20px 0px 16px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.acknowedge-termsv2>.row1 {
    padding: 0px 0px 0px 12px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.row2 {
    padding: 12px 0px 12px 26px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.pdpatitle {
    padding: 20px 0px 4px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.acknowedge-termsv2>.descpdpa {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.descpdpa>.pdpa1 {
    margin: 8px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.pdpa2 {
    margin: 16px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.row19 {
    border-color: #000000;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.acknowedge-termsv2>.descpdpa>.row19>.rcc1 {
    margin: 0px 16px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.row19>.rcc2 {
    margin: 0px 16px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.label16 {
    margin: 24px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.label17 {
    margin: 24px 0px 0px 0px;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.acknowedge-termsv2 {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.acknowedge-termsv2>.acknowledge-selectall {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.acknowledge-selectall>.sa1 {
    margin: 16px 0px 0px 8px;
}

.acknowedge-termsv2>.acknowledge-title {
    padding: 20px 0px 16px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.acknowedge-termsv2>.row1 {
    padding: 0px 0px 0px 12px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.row2 {
    padding: 12px 0px 12px 26px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.pdpatitle {
    padding: 20px 0px 4px 0px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    max-width: 1200px;
}

.acknowedge-termsv2>.descpdpa {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.acknowedge-termsv2>.descpdpa>.pdpa1 {
    margin: 8px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.pdpa2 {
    margin: 16px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.row19 {
    border-color: #000000;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
    height: 50px;
}

.acknowedge-termsv2>.descpdpa>.row19>.rcc1 {
    margin: 0px 16px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.row19>.rcc2 {
    margin: 0px 16px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.label16 {
    margin: 24px 0px 0px 0px;
}

.acknowedge-termsv2>.descpdpa>.label17 {
    margin: 24px 0px 0px 0px;
}
}

