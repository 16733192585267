
.page-title {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.page-title>.row1 {
    padding: 20px 0px 0px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row1>.image-button7 {
    width: 150px;
    height: 60px;
}

.page-title>.row2 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row3 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: none;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row4 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: none;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.page-title {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.page-title>.row1 {
    padding: 20px 0px 0px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row2 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: none;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row3 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: none;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row4 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.page-title {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
}

.page-title>.row1 {
    padding: 20px 0px 0px 20px;
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row2 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: none;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row3 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.page-title>.row4 {
    padding: 20px 0px 20px 0px;
    background-color: rgba(245,245,245,1.00);
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: none;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}
}

