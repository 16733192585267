
.acknowledgement-of-terms-item {
    background-color: #ffffff;
    border-color: #ffffff;
    display: block;
    originaldisplayvalue: block;
    border-width: 0px;
    border-style: solid;
}

.acknowledgement-of-terms-item>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}


/* ============================================================== */
/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {


.acknowledgement-of-terms-item {
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 0px;
    border-style: solid;
}

.acknowledgement-of-terms-item>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}
}


/* ============================================================== */
/* Mobile Mode */

@media screen and (max-device-width: 480px) {


.acknowledgement-of-terms-item {
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 0px;
    border-style: solid;
}

.acknowledgement-of-terms-item>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}
}

