/* =================================================== */
/* Popup */

.dpopup {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    overflow: hidden;
    
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.2s;
}

.dpopup>.window {
    animation: moveInUp 0.2s ease-in-out;
    box-shadow: -3px 0px 6px rgb(0 0 0 / 16%);
    overflow: hidden;
}

.dpopup>.window>.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dpopup>.window>.header>.title {
    flex: 1;
}

.dpopup>.window>.header>.close>svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.dpopup>.window>.content {
    height: calc(100% - 60px);
    overflow: auto;
}

/* ======================================================== */

.dpopup.sample {
    
}

.dpopup.sample>.window {
    width: 80%;
    height: 80%;
}

.dpopup.sample>.window>.header {
    border-bottom: 1px solid #BDC5C0;
    padding: 0px 12px;
    font-size: 16px;
    font-weight: 600;
    color: #22252B;
}