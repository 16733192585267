.thank-you-v2 {
    background-color: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    display: block;
    border-width: 0px;
    border-style: solid;
    height: 100%;
}

.thank-you-v2>.row1 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row2 {
    border-color: #000000;
    margin: 38px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row2>.label8 {
    margin: 8px 0px 0px 0px;
}

.thank-you-v2>.row2>.label9 {
    margin: 4px 0px 0px 0px;
}

.thank-you-v2>.row3 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    originaldisplayvalue: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row3>.label10 {
    margin: 4px 0px 0px 0px;
}

.thank-you-v2>.row4 {
    border-color: #000000;
    margin: 28px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row4>.row17 {
    border-color: #000000;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row4>.row17>.label10 {
    margin: 0px 8px 0px 0px;
}

.thank-you-v2>.row4>.row18 {
    border-color: #000000;
    margin: 4px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row4>.row18>.label10 {
    margin: 0px 8px 0px 0px;
}

.thank-you-v2>.row5 {
    border-color: #000000;
    margin: 38px 0px 0px 0px;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row13 {
    border-color: #000000;
    margin: 60px 0px 0px 0px;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-width: 0px;
    width: 100%;
    border-style: solid;
}

.thank-you-v2>.row13>.image14 {
    margin: 0px 20px 0px 0px;
    width: 100px;
    height: 100px;
}

.thank-you-v2>.row13>.image15 {
    width: 150px;
}


/* ============================================================== */


/* Tablet Mode */

@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    .thank-you-v2 {
        background-color: rgba(255, 255, 255, 0);
        border-color: #ffffff;
        display: block;
        border-width: 0px;
        border-style: solid;
    }
    .thank-you-v2>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row2 {
        border-color: #000000;
        margin: 38px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row2>.label8 {
        margin: 8px 0px 0px 0px;
    }
    .thank-you-v2>.row2>.label9 {
        margin: 4px 0px 0px 0px;
    }
    .thank-you-v2>.row3 {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row3>.label10 {
        margin: 4px 0px 0px 0px;
    }
    .thank-you-v2>.row4 {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row4>.row17 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .thank-you-v2>.row4>.row17>.label10 {
        margin: 0px 8px 0px 0px;
    }
    .thank-you-v2>.row4>.row18 {
        border-color: #000000;
        margin: 8px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .thank-you-v2>.row4>.row18>.label10 {
        margin: 0px 8px 0px 0px;
    }
    .thank-you-v2>.row5 {
        border-color: #000000;
        margin: 38px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row13 {
        border-color: #000000;
        margin: 60px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .thank-you-v2>.row13>.image14 {
        margin: 0px 20px 0px 0px;
        width: 120px;
        height: 70px;
    }
    .thank-you-v2>.row13>.image15 {
        width: 150px;
    }
}


/* ============================================================== */


/* Mobile Mode */

@media screen and (max-device-width: 480px) {
    .thank-you-v2 {
        background-color: rgba(255, 255, 255, 0);
        border-color: #ffffff;
        border-width: 0px;
        border-style: solid;
    }
    .thank-you-v2>.row1 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row2 {
        border-color: #000000;
        margin: 38px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row2>.label8 {
        margin: 8px 0px 0px 0px;
    }
    .thank-you-v2>.row2>.label9 {
        margin: 4px 0px 0px 0px;
    }
    .thank-you-v2>.row3 {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        originaldisplayvalue: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row3>.label10 {
        margin: 4px 0px 0px 0px;
    }
    .thank-you-v2>.row4 {
        border-color: #000000;
        margin: 28px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row4>.row17 {
        border-color: #000000;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .thank-you-v2>.row4>.row17>.label10 {
        margin: 0px 8px 0px 0px;
    }
    .thank-you-v2>.row4>.row18 {
        border-color: #000000;
        margin: 8px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .thank-you-v2>.row4>.row18>.label10 {
        margin: 0px 8px 0px 0px;
    }
    .thank-you-v2>.row5 {
        border-color: #000000;
        margin: 38px 0px 0px 0px;
        align-items: flex-start;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        border-width: 0px;
        width: 100%;
        border-style: solid;
    }
    .thank-you-v2>.row13 {
        border-color: #000000;
        margin: 60px 0px 0px 0px;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-width: 0px;
        width: 100%;
        border-style: solid;
        height: 50px;
    }
    .thank-you-v2>.row13>.image14 {
        margin: 0px 20px 0px 0px;
        width: 80px;
        height: 50px;
    }
    .thank-you-v2>.row13>.image15 {
        width: 150px;
    }
}